import { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  Grid,
  TextField,
  CurrencyInput,
  DataGrid,
  Button,
  FormControl,
} from "../../../../../components";
import {
  ProdutosService,
  UnidadeComercialService,
  notification,
} from "../../../../../services";
import { ProdutoSimilarDialog } from "../../components";
import { value } from "../../../../../helpers";
import {
  responseErros,
  formatPrice,
  unformatPrice,
} from "../../../../../utils";
import shortid from "shortid";
import { calcularPrecoProduto, calcularValorTotal } from "../../addOrcamentos";

const OrcamentosItens = ({
  id,
  orcamento,
  produtoList,
  setProdutoList,
  listaOrcamentosProdutos,
  setListaOcamentosProdutos,
  tabelaPrecoCliente,
  removerProdutoCadastrado,
  timeoutBuscaAutocomplete,
  buscarProdutosSimilares,
  produtosSimilares,
  origensMercadoriasList,
}) => {
  const inputRef = useRef();
  const unidadeComercialService = new UnidadeComercialService();
  const produtosService = new ProdutosService();
  const [listaUnidadesComerciais, setListaUnidadesComerciais] = useState([]);
  const [produto, setProduto] = useState(null);
  const [loadingAutoCompleteProd, setLoadingAutoCompleteProd] = useState();
  const [isProdutoEdit, setIsProdutoEdit] = useState(false);
  const [openProdutoSimilarDialog, setOpenProdutoSimilarDialog] =
    useState(false);

  const colunas = [
    {
      field: "codigo",
      headerName: "Cod.",
      flex: 100,
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "descricao",
      headerName: "Produto",
      flex: 400,
    },
    {
      field: "valorUnitario",
      headerName: "Unitário R$",
      flex: 200,
      valueGetter: (params) => formatPrice(parseFloat(params.value)),
    },
    {
      field: "quantidade",
      headerName: "Quantidade",
      flex: 150,
    },
    {
      field: "totalItem",
      headerName: "Total dos Itens R$",
      flex: 300,
      valueGetter: (params) =>
        params.value ? formatPrice(parseFloat(params.value)) : "",
    },
  ];

  const produtosColunasSimilares = [
    {
      field: "codigo",
      headerName: "Cod.",
      flex: 280,
      valueGetter: (params) => formatPrice(params.row.produto.codigo),
    },
    {
      field: "descricao",
      headerName: "Produtos",
      flex: 700,
      valueGetter: (params) => formatPrice(params.row.produto.descricao),
    },
    {
      field: "estoqueAtual",
      headerName: "Estoque",
      flex: 350,
      valueGetter: (params) => params.row.produto.estoqueAtual,
    },
    {
      field: "precoCompra",
      headerName: "Preço Compra",
      flex: 500,
      valueGetter: (params) => formatPrice(params.row.produto.precoCompra),
    },
    {
      field: "precoVenda",
      headerName: "Preço Venda",
      flex: 500,
      valueGetter: (params) => formatPrice(params.row.produto.precoVenda),
    },
    {
      field: "acoes",
      sortable: false,
      disableClickEventBubbling: true,
      renderHeader: () => {
        return (
          <i
            className="ph-fill ph-note-pencil"
            style={{ fontSize: 20, marginLeft: 14 }}
          ></i>
        );
      },
      renderCell: (params) => {
        return (
          <i
            className="ph-fill ph-paper-plane-right"
            style={{ fontSize: 20, marginLeft: 14 }}
            onClick={() => {
              setOpenProdutoSimilarDialog(false);
              const produtoSimilar = value.autoComplete(
                produtoList,
                params.row.similarId
              );
              onChangeAutocomplete("produtoId", produtoSimilar);
            }}
          ></i>
        );
      },
    },
  ];

  const buscarProdutos = (filtros) => {
    const filtro = {
      ...filtros,
      orderBy: "codigo",
      order: "asc",
      ativado: true,
      limite: 20,
    };
    produtosService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setLoadingAutoCompleteProd(false);
        setProdutoList(result.data.rows);
        if (result.data.rows?.length === 1) {
          onChangeAutocomplete("produtoId", result.data.rows[0], true);
        }
      } else {
        setLoadingAutoCompleteProd(false);
        responseErros(result);
      }
    });
  };

  const onInputChangeProdutos = (event) => {
    const eventValue = event.target?.value.includes(",")
      ? event.target?.value.split(",")[0]
      : event.target?.value;
    if (
      event.key === "Backspace" &&
      inputRef.current === document.activeElement &&
      eventValue === ""
    ) {
      setProdutoList([]);
    }
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const filtros = {
        codigo: eventValue,
        descricao: eventValue,
      };
      if (
        typeof parseFloat(eventValue) === "number" &&
        eventValue?.length >= 8 &&
        eventValue?.length <= 13
      ) {
        filtros.codigoBarra = eventValue;
      }
      setLoadingAutoCompleteProd(true);
      timeoutBuscaAutocomplete(buscarProdutos(filtros));
    }
  };

  const buscarUnidadesComerciais = () => {
    unidadeComercialService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setListaUnidadesComerciais(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const onClickDeleteOrcamentosItens = async function (idProduto) {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir essa conta?`
    );
    if (retornoAlerta.isConfirmed) {
      if (id) {
        removerProdutoCadastrado(idProduto);
      } else {
        setListaOcamentosProdutos((prevState) =>
          prevState.filter((produto) => produto.id != idProduto)
        );
      }
    }
  };

  const onChangeAutocomplete = (name, value, isBuscaCodBar) => {
    document.getElementById("produtoId").blur();
    if (value) {
      const unidadeProduto = listaUnidadesComerciais.find(
        (unidade) => unidade.id == value.unidadeComercialVendaId
      );
      const precoProduto = calcularPrecoProduto(
        value,
        value.precoVenda,
        orcamento,
        tabelaPrecoCliente
      );
      const itemTemp = {
        ...value,
        quantidade: 1,
        unidade: unidadeProduto ? unidadeProduto?.codigo : "",
        estoqueAtual: value?.estoqueProduto?.estoque
          ? `${value.estoqueProduto.estoque}`
          : 0,
        valorUnitario: precoProduto,
        totalItem: precoProduto * 1,
        [name]: value ? value.id : undefined,
        descricao: value ? value.descricao : "",
        codigo: value?.codigo,
        tributosIcmsOrigem: origensMercadoriasList.find(
          (item) => item.id == value.origemMercadoriaId
        )?.codigo,
        ncm: value?.ncmCodigo,
      };
      setProduto(itemTemp);
      /* implementar regras produtos similares
      buscarProdutosSimilares(value.id);*/
      if (!isBuscaCodBar) {
        document.getElementById("buttonAdicionar").focus();
      } else {
        handleAdicionarProduto(itemTemp);
      }
    } else setProduto(null);
  };

  useEffect(() => {
    if (listaOrcamentosProdutos.length > 0 && produto) {
      const produtosAtualizados = listaOrcamentosProdutos.map((produto) => {
        const precoProduto = calcularPrecoProduto(
          produto,
          produto.precoVenda ?? produto.valorUnitario,
          orcamento,
          tabelaPrecoCliente
        );
        return {
          ...produto,
          valorUnitario: precoProduto,
          totalItem: precoProduto * produto.quantidade,
        };
      });
      setListaOcamentosProdutos(produtosAtualizados);
    }
  }, [tabelaPrecoCliente, orcamento.condicaoPagamentoId]);

  const handleInputChange = (event) => {
    const produtoTemp = {
      ...produto,
      [event.target.name]: event.target.value || null,
    };
    produtoTemp.totalItem = calcularValorTotal(produtoTemp);
    setProduto(produtoTemp);
  };

  const handleInputBlur = (event) => {
    const produtoTemp = {
      ...produto,
      [event.target.name]: unformatPrice(event.target.value) || null,
    };
    produtoTemp.totalItem = calcularValorTotal(produtoTemp);
    setProduto(produtoTemp);
  };

  const onClickEditOrcamentosItens = async (id) => {
    const item = listaOrcamentosProdutos.find((item) => item.id === id);
    if (item.status === "FECHADO") {
      return notification.alertaGenericos(
        "Ítem já importado não pode ser editado"
      );
    }
    if (item) setIsProdutoEdit(true);
    setProduto(item);
  };

  const validarAddProduto = (produto) => {
    if (!produto) {
      notification.alertaGenericos("Selecione um produto para adicionar");
      return true;
    }
    if (!produto.quantidade) {
      notification.erroValidacao("Quantidade do produto");
      return true;
    }
    if (!produto.unidade) {
      notification.erroValidacao("Unidade do produto");
      return true;
    }
  };

  const handleAdicionarProduto = (produto) => {
    if (validarAddProduto(produto)) return;
    if (isProdutoEdit) {
      const produtoIndex = listaOrcamentosProdutos.findIndex(
        (item) => item.id === produto.id
      );
      if (produtoIndex >= 0) {
        setListaOcamentosProdutos((prevState) => [
          ...prevState.map((item, _index) =>
            _index === produtoIndex
              ? {
                  ...item,
                  ...produto,
                }
              : item
          ),
        ]);
      }
    } else {
      setListaOcamentosProdutos((prevState) => [
        ...prevState,
        {
          ...produto,
          id: shortid.generate(),
        },
      ]);
    }
    document.getElementById("produtoId").focus();
    setProduto(null);
  };

  useEffect(() => {
    buscarUnidadesComerciais();
  }, []);

  return orcamento.status === "CANCELADO" || orcamento.status === "BAIXADO" ? (
    <>
      <DataGrid
        rows={listaOrcamentosProdutos}
        rowCount={listaOrcamentosProdutos.length}
        columns={colunas}
      />
      <ProdutoSimilarDialog
        produto={produto}
        openProdutoSimilarDialog={openProdutoSimilarDialog}
        setOpenProdutoSimilarDialog={setOpenProdutoSimilarDialog}
        buscarProdutosSimilares={buscarProdutosSimilares}
        produtosColunasSimilares={produtosColunasSimilares}
        produtosSimilares={produtosSimilares}
      />
    </>
  ) : (
    <Grid container className="position-relative">
      <Grid
        container
        spacing={2}
        className="mb-2"
        alignItems="center"
        columns={15}
      >
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="produtoId"
              name="produtoId"
              options={produtoList}
              disabled={id && orcamento.status !== "ABERTO"}
              autoHighlight
              loading={loadingAutoCompleteProd}
              loadingText="Carregando"
              noOptionsText="Digite e clique no ícone para buscar"
              renderOption={(props, option) => {
                let descricaoProduto = `${option?.codigo} - ${option?.descricao}`;
                return option ? (
                  <li {...props}>
                    <div className="d-flex justify-content-between w-100">
                      <div>{descricaoProduto}</div>
                      <div>{option?.estoqueProduto?.estoque}</div>
                    </div>
                  </li>
                ) : (
                  ""
                );
              }}
              getOptionLabel={(option) => {
                let descricaoProduto = `${option?.codigo} - ${option?.descricao}`;
                return option ? descricaoProduto : "";
              }}
              filterSelectedOptions
              onChange={(e, value) => onChangeAutocomplete("produtoId", value)}
              value={produto ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Buscar produto"
                  required
                  onKeyDown={(e) => onInputChangeProdutos(e)}
                  inputRef={inputRef}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <TextField
            id="estoque"
            name="estoque"
            label="Estoque"
            margin="normal"
            variant={"outlined"}
            disabled
            value={value.text(produto?.estoqueAtual)}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            id="unidade"
            name="unidade"
            label="Unidade"
            margin="normal"
            variant={"outlined"}
            disabled
            value={value.text(produto?.unidade)}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="quantidade"
            name="quantidade"
            label="Quantidade"
            variant={!produto ? "filled" : "outlined"}
            disabled={!produto}
            InputProps={
              produto?.fracionavel
                ? { inputProps: { min: 0.01, step: 0.01 } }
                : { inputProps: { min: 1, step: 1 } }
            }
            value={value.text(produto?.quantidade)}
            onChange={(e) => {
              if (e.target.value <= 0) e.target.value = "";
              if (produto?.fracionavel) {
                e.target.value = Number(e.target.value);
              } else {
                e.target.value = Math.trunc(Number(e.target.value));
              }
              handleInputChange(e);
            }}
            margin="normal"
            type="number"
            required
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorUnitario"
            name="valorUnitario"
            label="Valor Unitário R$"
            variant={!produto ? "filled" : "outlined"}
            disabled={!produto}
            value={value.text(produto?.valorUnitario)}
            onBlur={handleInputBlur}
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="totalItem"
            name="totalItem"
            label="Total Item R$"
            variant={!produto ? "filled" : "outlined"}
            disabled={!produto}
            value={value.text(produto?.totalItem)}
            onBlur={handleInputBlur}
          />
        </Grid>
        <Grid item xs={1} sx={{ mt: "0.5em" }}>
          {isProdutoEdit ? (
            <Button
              id="buttonAdicionar"
              variant="contained"
              disabled={id && orcamento.status !== "ABERTO"}
              size="small"
              sx={{
                height: "35px",
                borderRadius: "50%",
                minWidth: "15px",
                boxShadow: "none",
              }}
              onClick={() => handleAdicionarProduto(produto)}
            >
              <i
                className="ph-bold ph-arrows-clockwise"
                style={{ color: "#fff", fontSize: 15 }}
              ></i>
            </Button>
          ) : (
            <Button
              id="buttonAdicionar"
              variant="contained"
              color="success"
              disabled={id && orcamento.status !== "ABERTO"}
              size="small"
              sx={{
                height: "35px",
                borderRadius: "50%",
                minWidth: "15px",
                boxShadow: "none",
              }}
              onClick={() => handleAdicionarProduto(produto)}
            >
              <i
                className="ph-bold ph-plus"
                style={{ color: "#fff", fontSize: 15 }}
              ></i>
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid style={{ marginTop: 32 }} item xs={12}>
          <DataGrid
            style={{ zIndex: 1 }}
            rows={listaOrcamentosProdutos}
            rowCount={listaOrcamentosProdutos.length}
            columns={colunas}
            editShow={
              orcamento.status !== "CANCELADO" && orcamento.status !== "FECHADO"
            }
            deleteShow={
              orcamento.status !== "CANCELADO" && orcamento.status !== "FECHADO"
            }
            onClickEdit={onClickEditOrcamentosItens}
            onClickDelete={onClickDeleteOrcamentosItens}
          />
        </Grid>
      </Grid>
      <ProdutoSimilarDialog
        produto={produto}
        openProdutoSimilarDialog={openProdutoSimilarDialog}
        setOpenProdutoSimilarDialog={setOpenProdutoSimilarDialog}
        buscarProdutosSimilares={buscarProdutosSimilares}
        produtosColunasSimilares={produtosColunasSimilares}
        produtosSimilares={produtosSimilares}
      />
    </Grid>
  );
};

export default OrcamentosItens;
