import shortid from "shortid";

export const meioPagamentoEnum = {
  "01": "fpDinheiro",
  "02": "fpCheque",
  "03": "fpCartaoCredito",
  "04": "fpCartaoDebito",
  "05": "fpCreditoLoja",
  10: "fpValeAlimentacao",
  11: "fpValeRefeicao",
  12: "fpValePresente",
  13: "fpValeCombustivel",
  14: "fpDuplicataMercantil",
  15: "fpBoletoBancario",
  16: "fpDepositoBancario",
  17: "fpPagamentoInstantaneoPIX",
  18: "fpTransferenciabancaria",
  19: "fpProgramadefidelidade",
  90: "fpSemPagamento",
  99: "fpOutro",
};

export const parseXml = (content) => {
  const parser = new DOMParser();
  return parser.parseFromString(content, "application/xml");
};

export const getOrigem = (ICMS, origensMercadoriasList) => {
  return origensMercadoriasList.find(({ codigo }) => codigo == ICMS?.orig);
};

export const getEnumPagamento = (codigo) => {
  return meioPagamentoEnum[codigo] ? meioPagamentoEnum[codigo] : codigo;
};

export const getPagamentoDuplicataXml = (
  pagamentos,
  duplicatas,
  dataEmissao
) => {
  if (!pagamentos?.length && !duplicatas?.length) {
    return [];
  }
  if (pagamentos?.length != duplicatas?.length) {
    if (duplicatas?.length > pagamentos?.length) {
      return duplicatas.map((duplicata) => ({
        ...duplicata,
        tPag: getEnumPagamento(pagamentos[0].tPag),
      }));
    } else {
      return pagamentos.map((pagamento, index) => {
        if (index === 0) {
          return {
            nDup: Math.floor(Math.random() * 200),
            dVenc: dataEmissao,
            vDup: pagamento.vPag,
            tPag: getEnumPagamento(pagamento.tPag),
          };
        } else {
          const duplicataEncontrada = duplicatas.find(
            (_, indexDuplicata) => indexDuplicata === index - 1
          );
          return {
            ...duplicataEncontrada,
            tPag: getEnumPagamento(pagamento.tPag),
          };
        }
      });
    }
  } else {
    return duplicatas.map((duplicata, index) => ({
      ...duplicata,
      tPag: getEnumPagamento(pagamentos[index].tPag),
    }));
  }
};

export function cadastroProdutosDto(produto, index) {
  if (produto?.produtoCadastrado) {
    return {
      ...produto,
      fornecedores: [
        {
          id: produto.fornecedorId,
          codigoProduto: produto.codigoProduto,
          quantidadeEmbalagem: 1,
        },
      ],
      origemMercadoriaId: produto.origemMercadoriaId,
      ncmId: produto.ncmId,
      ncmCodigo: produto.ncmCodigo,
      unidadeComercialCompraId: produto.unidadeComercialCompraId,
      unidadeComercialVendaId: produto.unidadeComercialCompraId,
      id: produto.id ? produto.id : shortid.generate(),
      ordem: index + 1,
    };
  } else {
    return {
      ...produto,
      id: shortid.generate(),
      ordem: index + 1,
      fornecedores: [
        {
          id: produto.fornecedorId,
          codigoProduto: produto.codigoProduto,
          quantidadeEmbalagem: 1,
        },
      ],
      origemMercadoriaId: produto.origemMercadoriaId,
      ncmId: produto.ncmId,
      ncmCodigo: produto.ncmCodigo,
      unidadeComercialCompraId: produto.unidadeComercialCompraId,
      unidadeComercialVendaId: produto.unidadeComercialCompraId,
    };
  }
}

export function calculoCustoNfEntradaItem(item) {
  return (
    parseFloat(item.precoCompra) +
    item.impostoValorIcmsStAliquota / (item.quantidade || 1) +
    item.tributosValorIpiAliquota / (item.quantidade || 1) +
    item.valorFrete / (item.quantidade || 1)
  );
}
