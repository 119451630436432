import {
  Grid,
  Tooltip,
  CurrencyInput,
  TextFielDecimalNumbers,
} from "../../../../components";
import { unformatPrice } from "../../../../utils";
import { validarLucro } from "../addProduto";

const FormPrecoCusto = (props) => {
  const handleChangeInput = (event) => {
    const valor = unformatPrice(event.target.value);
    let produtoTemp = {};
    switch (event.target.name) {
      case "precoCompra":
        produtoTemp = calcularPrecoCompra(valor);
        break;
      case "precoCusto":
        produtoTemp = calcularPrecoCusto(valor);
        break;
      case "valorComissaoVendedor":
        produtoTemp = calcularPercentualComissaoVendedor(valor);
        break;
      case "percentualComissaoVendedor":
        produtoTemp = calcularValorComissaoVendedor(valor);
        break;
      default:
        produtoTemp = props.produto;
        break;
    }
    props.setProduto({
      ...produtoTemp,
      [event.target.name]: valor || undefined,
    });
  };

  const calcularValorComissaoVendedor = (percentual) => {
    const valorComissaoVendedor = props.produto.precoVenda * (percentual / 100);
    return {
      ...props.produto,
      valorComissaoVendedor,
    };
  };

  const calcularPercentualComissaoVendedor = (valor) => {
    const percentualComissaoVendedor = (valor * 100) / props.produto.precoVenda;
    return {
      ...props.produto,
      percentualComissaoVendedor,
    };
  };

  const calcularPrecoCusto = (valueCompra) => {
    const produtoTemp = {
      ...props.produto,
      precoCusto: valueCompra,
    };
    if (valueCompra && valueCompra !== "") {
      produtoTemp.precoVenda = props.calculoPrecoVenda(produtoTemp);
      return produtoTemp;
    } else return props.produto;
  };

  const calcularPrecoCompra = (valueCompra) => {
    if (
      parseFloat(valueCompra) > parseFloat(props.produto.precoCusto) ||
      props.produto.precoCusto === "" ||
      props.produto.precoCusto === props.produto.precoCompra
    ) {
      return {
        ...props.produto,
        precoCusto: valueCompra,
      };
    } else {
      return props.produto;
    }
  };

  const handleBlurCusto = () => {
    if (
      parseFloat(props.produto.precoCusto || 0) <
      parseFloat(props.produto.precoCompra)
    ) {
      const produtoTemp = {
        ...props.produto,
        precoCusto: props.produto.precoCompra,
      };
      if (produtoTemp.precoCusto) {
        produtoTemp.precoVenda = validarLucro(produtoTemp, props.configuracoes);
      }
      props.setProduto(produtoTemp);
    }
  };

  const handleBlurPrecoVenda = () => {
    if (
      parseFloat(props.produto.precoVenda) <
      parseFloat(props.produto.precoCompra)
    ) {
      const produtoTemp = {
        ...props.produto,
        precoVenda: props.produto.precoCompra,
      };
      props.setProduto(produtoTemp);
    }
  };

  const nextFocusInput = (event) => {
    let inputCount = 2;
    if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
      const { form } = event.target;
      const index = Array.from(form.elements).indexOf(event.target);
      let element = form.elements[index + inputCount];
      if (element?.disabled) {
        element = form.elements[index + inputCount + 2];
      }
      if (element) {
        element.focus();
      }
      event.preventDefault();
    }
  };

  return (
    <form>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <CurrencyInput
            id="precoCompra"
            name="precoCompra"
            label="Preço Compra"
            disabled={props.produto.tipo.id === "kit" ? true : false}
            error={props.inputErros.get("precoCompra")}
            onKeyDown={(e) => nextFocusInput(e)}
            value={props.produto.precoCompra ?? ""}
            onBlur={(event) => handleChangeInput(event)}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <Tooltip
            title={
              <>
                Preço de custo deve ser igual ou
                <br />
                maior que o preço de compra
              </>
            }
            arrow
            placement="bottom"
            enterTouchDelay={0}
            enterDelay={500}
          >
            <CurrencyInput
              id="precoCusto"
              name="precoCusto"
              label="Preço Custo"
              required
              value={props.produto.precoCusto ?? ""}
              onKeyDown={(e) => nextFocusInput(e)}
              onBlur={(event) => {
                handleChangeInput(event);
                handleBlurCusto(event);
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="precoMedio"
            name="precoMedio"
            label="Preço Médio"
            disabled={true}
            onKeyDown={(e) => nextFocusInput(e)}
            value={props.produto.precoMedio ?? ""}
            onBlur={(event) => handleChangeInput(event)}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip
            title={
              <>
                Lucro deve ser menor que 100%
                <br />
                em cálculo de margem inversa (MI)
              </>
            }
            arrow
            placement="bottom"
            enterTouchDelay={0}
            enterDelay={500}
          >
            <TextFielDecimalNumbers
              id="lucroDesejado"
              name="lucroDesejado"
              label="Lucro Desejado"
              variant="outlined"
              onKeyDown={(e) => nextFocusInput(e)}
              value={props.produto.lucroDesejado ?? ""}
              onBlur={props.handleInputLucroVenda}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <CurrencyInput
            id="precoVenda"
            name="precoVenda"
            label="Preço Venda"
            error={props.inputErros.get("precoVenda")}
            value={props.produto.precoVenda ?? ""}
            onKeyDown={(e) => nextFocusInput(e)}
            onBlur={(e) => {
              props.handleInputLucroVenda(e);
              handleBlurPrecoVenda(e);
            }}
            required
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default FormPrecoCusto;
