import { useState, useEffect } from "react";
import {
  Autocomplete,
  Grid,
  TextField,
  FormControlLabel,
  FormGroup,
  FormControl,
  Select,
  Switch,
  Checkbox,
  MenuItem,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import { constantes } from "./resources";
import {
  MeioPagamentoService,
  BancosCaixasService,
  notification,
  history,
  ConfiguracoesService,
} from "../../../services";
import { InputErros, value, PermisoesHelper } from "../../../helpers";
import { userLogs, responseErros, voltar } from "../../../utils";
import addMeioPagamentoValidator from "./middlewares/addMeioPagamento.validator";
import "./meio-pagamento.css";
import { useImportContext } from "../../../contexts/import.context";
import { MeioPagamento } from "../entities";

const AtivadoComponent = ({ meioPagamento, setMeioPagamento }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={meioPagamento?.ativado ? meioPagamento.ativado : false}
          onChange={() =>
            setMeioPagamento((pagamento) => {
              return {
                ...pagamento,
                ativado: !meioPagamento?.ativado,
              };
            })
          }
          name="ativado"
          color="primary"
        />
      }
      label={meioPagamento?.ativado ? "Ativado" : "Desativado"}
    />
  );
};

const AddMeioPagamento = ({ match }) => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("meios-pagamento-visualizar");
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [initialMeioPagamentoState, setInitialMeioPagamentoState] = useState({
    ativado: true,
  });
  const [meioPagamento, setMeioPagamento] = useState({
    ativado: true,
  });
  const [contas, setContas] = useState([]);
  const [userLog, setUserLog] = useState({});
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const configuracoesService = new ConfiguracoesService();
  const meioPagamentoService = new MeioPagamentoService();
  const bancosCaixasService = new BancosCaixasService();

  async function buscarDadosIniciais() {
    await buscaMeioPagamento();
  }

  useEffect(() => {
    if (id) {
      buscarDadosIniciais();
    }
    buscaContas();
  }, [id]);

  const buscaMeioPagamento = async () => {
    setLoading(true);
    const res = await meioPagamentoService.getById(id);
    if (!res.isAxiosError) {
      if (!res.data) {
        initialCollapses();
        voltar();
        return;
      }
      setInitialMeioPagamentoState(res.data);
      setMeioPagamento(res.data);
      userLogs(res.data).then((logs) => {
        setUserLog(logs);
      });
    } else {
      responseErros(res);
    }
    setLoading(false);
  };

  const buscaContas = async () => {
    const filtro = {
      ativado: true,
      nonPaginated: true,
    };
    bancosCaixasService.getAll(filtro).then((res) => {
      if (!res.isAxiosError) {
        setContas(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const handleInputChange = (e) => {
    setMeioPagamento((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onChangeAutocomplete = (name, value) => {
    const meioPagamentoTemp = {
      ...meioPagamento,
      [name]: value ? value.id : undefined,
    };
    setMeioPagamento(meioPagamentoTemp);
  };

  const handleCheckBox = (event) => {
    setMeioPagamento((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleSubmit = async (indiceSelecionado) => {
    setLoading(true);
    const dadosMeioPagamento = new MeioPagamento(meioPagamento);
    if (!dadosMeioPagamento.getBancoCaixaId()) {
      const configuracao = await configuracoesService.get();
      if (configuracao.data.count) {
        const data = configuracao.data.rows[0];
        dadosMeioPagamento.setBancoCaixaId(data?.bancoCaixaId);
      }
    }
    addMeioPagamentoValidator
      .validate(dadosMeioPagamento, { abortEarly: false })
      .then(async () => {
        if (id) {
          const response = await meioPagamentoService.atualizar(
            id,
            dadosMeioPagamento
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            history.goBack();
          } else {
            responseErros(response);
          }
        } else {
          const response = await meioPagamentoService.cadastrar(
            dadosMeioPagamento
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            if (indiceSelecionado === 0) {
              history.goBack();
            } else {
              handleCancelar();
            }
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const handleCancelar = () => {
    setMeioPagamento(initialMeioPagamentoState);
  };

  return (
    <AddLayout
      id={id}
      title={
        meioPagamento?.id
          ? "Editar meio de pagamento"
          : "Cadastrar meio de pagamento"
      }
      onClickSalvar={handleSubmit}
      loading={loading}
      userLog={meioPagamento?.id ? userLog : null}
      disableAtualizar={!permissoesHelper.temPermisao("meios-pagamento-editar")}
      actions={
        <AtivadoComponent
          meioPagamento={meioPagamento}
          setMeioPagamento={setMeioPagamento}
        />
      }
    >
      <Grid container spacing={1}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="descricao"
              name="descricao"
              label="Descrição"
              variant="outlined"
              margin="normal"
              value={meioPagamento?.descricao ? meioPagamento.descricao : ""}
              onChange={(e) => {
                setInputErro([]);
                handleInputChange(e);
              }}
              fullWidth
              required
              error={inputErros.get("descricao")}
            />
          </Grid>
          <Grid item xs={3} className={"nfePagCss"}>
            <Select
              label="Nfe Pagamento"
              id="nfePagamento"
              name="nfePagamento"
              value={value.text(meioPagamento.nfePagamento)}
              onChange={(event) => handleInputChange(event)}
              required
              error={inputErros.get("nfePagamento")}
              margin="normal"
              fullWidth
            >
              {constantes.nfPagamentosList.map((pagamento) => (
                <MenuItem
                  className={"menuItem"}
                  key={pagamento.value}
                  value={pagamento.value}
                >
                  {pagamento.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                id="bancoCaixaId"
                name="bancoCaixaId"
                options={contas}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) => option.nome ?? ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Conta"
                    variant="outlined"
                    error={inputErros.get("bancoCaixaId")}
                    required
                  />
                )}
                onChange={(_, newValue) =>
                  onChangeAutocomplete("bancoCaixaId", newValue)
                }
                value={value.autoComplete(contas, meioPagamento?.bancoCaixaId)}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="m-3">
          <Grid item xs={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      meioPagamento?.baixaAutomatica
                        ? meioPagamento.baixaAutomatica
                        : false
                    }
                    onClick={handleCheckBox}
                    color="primary"
                    value={meioPagamento?.baixaAutomatica}
                    name="baixaAutomatica"
                  />
                }
                label="Baixa Automática"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      meioPagamento?.cartaoCreditoDebito
                        ? meioPagamento.cartaoCreditoDebito
                        : false
                    }
                    onClick={handleCheckBox}
                    color="primary"
                    value={meioPagamento?.cartaoCreditoDebito}
                    name="cartaoCreditoDebito"
                  />
                }
                label="Cartão Crédito/Débito"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      meioPagamento?.disponivelPdv
                        ? meioPagamento.disponivelPdv
                        : false
                    }
                    onClick={handleCheckBox}
                    color="primary"
                    value={meioPagamento?.disponivelPdv}
                    name="disponivelPdv"
                  />
                }
                label="Disponível no PDV"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </AddLayout>
  );
};

export default AddMeioPagamento;
