import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
} from "../../../../components";

const ModulosAcordionComponente = ({
  modulo,
  setPerfilPermisaoModulo,
  perfilPermisaoModulo,
}) => {
  const onChangeSwitch = (event, id) => {
    setPerfilPermisaoModulo({
      ...perfilPermisaoModulo,
      [event.target.name]: {
        pctPermissoesModuloId: id,
        habilitado: event.target.checked,
      },
    });
  };

  const valueSwitch = (nome) => {
    if (perfilPermisaoModulo?.[nome]) {
      return perfilPermisaoModulo[nome].habilitado;
    }
    return false;
  };

  return (
    <Grid item xs={12}>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <i
              className="ph-fill ph-caret-down"
              style={{ color: "#494C62", fontSize: 18 }}
            ></i>
          }
        >
          <h6 className="text-uppercase">{modulo?.descricao}</h6>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3 }}>
          {modulo?.permisaoModulo?.length > 0 && (
            <div className="d-flex w-100 gap-2 flex-wrap">
              {modulo.permisaoModulo
                .sort((a, b) => a?.descricao > b?.descricao)
                .map((permissao) => {
                  return (
                    permissao?.descricao && (
                      <div key={permissao.id} className="flex-fill">
                        <FormControlLabel
                          label={permissao.descricao.toUpperCase()}
                          control={
                            <Switch
                              id={permissao.nome}
                              name={permissao.nome}
                              checked={valueSwitch(permissao.nome)}
                              onChange={(event) => {
                                onChangeSwitch(event, permissao.id);
                              }}
                              color="primary"
                            />
                          }
                        />
                      </div>
                    )
                  );
                })}
            </div>
          )}
        </AccordionDetails>
        {modulo?.filhos?.length > 0 &&
          modulo?.filhos.map((modulo, index) => (
            <div key={`modulo-${index}`}>
              <ModulosAcordionComponente
                modulo={modulo}
                setPerfilPermisaoModulo={setPerfilPermisaoModulo}
                perfilPermisaoModulo={perfilPermisaoModulo}
              />
            </div>
          ))}
      </Accordion>
    </Grid>
  );
};

export default ModulosAcordionComponente;
