import React, { useState, useEffect, useRef } from "react";
import {
  CardContent,
  Grid,
  Paper,
  Tabs,
  Tab,
  TextField,
  FormControl,
  CircularProgress,
  Autocomplete,
  DatePicker,
  TabPanel,
  FullscreenDialog,
  Button,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import {
  PessoaService,
  notification,
  PedidosService,
  PedidosItensService,
  MeioPagamentoService,
  ProdutosService,
  EnderecoService,
  OperacaoFiscalService,
  history,
  ConfiguracoesService,
  CondicoesPagamentoService,
  TabelaPrecoService,
  SessionService,
  ColaboradorService,
  OrigemMercadoriaService,
} from "../../../services";
import { InputErros, PermisoesHelper, value } from "../../../helpers";
import { responseErros, formatDate, userLogs, voltar } from "../../../utils";
import { pedidosValidator, enderecoValidator } from "./middlewares";
import {
  ButtonMenu,
  PedidosItens,
  PedidosPagamentos,
  PedidosCampos,
  PedidosDadosClientes,
  ComissaoVendedorDialog,
  CampoPersonalizadoDialog,
  ResumoTotal,
} from "./components";
import { useImportContext } from "../../../contexts/import.context";
import AddPessoas from "../../pessoas/addPessoa/addPessoa.view";
import {
  tabProps,
  calcularValorTotal,
  mapearEndereco,
  totalItensLista,
  totalPedido,
  encontrarEnderecoPadrao,
  totalPagamentos,
  compararPagamentosTotalPedidos,
  verificaBaixaAutomatica,
} from "./addPedidos";
import { Pedido } from "./entities";
import { useMediaQuery } from "@mui/material";

const configuracoesService = new ConfiguracoesService();
const pedidosService = new PedidosService();
const pessoaService = new PessoaService();
const enderecoService = new EnderecoService();
const sessionService = new SessionService();
const colaboradorService = new ColaboradorService();
const meioPagamentoService = new MeioPagamentoService();
const tabelaPrecoService = new TabelaPrecoService();
const condicoesPagamentoService = new CondicoesPagamentoService();
const produtosService = new ProdutosService();
const operacaoFiscalService = new OperacaoFiscalService();
const pedidosItensService = new PedidosItensService();
const permisoesHelper = new PermisoesHelper();
const origemMercadoriaService = new OrigemMercadoriaService();
const permissoesHelper = new PermisoesHelper();
permissoesHelper.validarPermisao("pedidos-visualizar");

const AddPedidosView = ({ match }) => {
  const { id } = match.params;
  const inputCliente = useRef();
  const [loading, setLoading] = useState(true);
  const [userLog, setUserLog] = useState({});
  const dadosUsuario = sessionService.getUsuario();
  const [isCadastroPessoaDialogOpen, setIsCadastroPessoaDialogOpen] =
    useState(false);
  const [loadingAutoCompleteCliente, setLoadingAutoCompleteCliente] =
    useState(false);
  const [loadingAutoCompleteProduto, setLoadingAutoCompleteProduto] =
    useState(false);
  const [abaValue, setAbaValue] = useState(0);
  const [initialPedidoState, setInitialPedidoState] = useState({
    dataEmissao: formatDate.toSend(new Date()),
    entrada: 0,
  });
  const [pedido, setPedido] = useState({
    dataEmissao: formatDate.toSend(new Date()),
    entrada: 0,
    desconto: 0,
    descontoPorcentagem: 0,
  });
  const prevStateRef = useRef(pedido);
  const [contadorModificacao, setContadorModificacao] = useState(0);
  //remover pedido dto e colocar em uma valiavel global do tipo classe
  const { pedidoDto, setPedidoDto, initialCollapses } = useImportContext();
  const [estados, setEstados] = useState([]);
  const [itemValorComissaoVendedor, setItemValorComissaoVendedor] =
    useState(null);
  const [itemPercentualComissaoVendedor, setItemPercentualComissaoVendedor] =
    useState(null);
  const [itemTotalComissaoVendedor, setItemTotalComissaoVendedor] =
    useState(null);
  const [clienteList, setClienteList] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [condicaoPagamentoList, setCondicaoPagamentoList] = useState([]);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [permitirVendaEstoqueNegativo, setPermitirVendaEstoqueNegativo] =
    useState(false);
  const [produtoList, setProdutoList] = useState([]);
  const [produtosSimilares, setProdutosSimilares] = useState([]);
  const [listaPedidosProdutos, setListaPedidosProdutos] = useState([]);
  const [cidadeId, setCidadeId] = useState(null);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [tabelaPrecoCliente, setTabelaPrecoCliente] = useState(null);
  const [endereco, setEndereco] = useState({
    padrao: false,
  });
  const [enderecoCliente, setEnderecoCliente] = useState(null);
  const [enderecoClienteDataGrid, setEnderecoClienteDataGrid] = useState([]);
  const [enderecoAleterado, setEnderecoAleterado] = useState(null);
  const [listaPedidosPagamentos, setListaPedidosPagamentos] = useState([]);
  const [origensMercadoriasList, setOrigensMercadoriasList] = useState([]);
  const [campoPersonalizado, setCampoPersonalizado] = useState({});
  const [openComissaoDialog, setOpenComissaoDialog] = useState(false);
  const [openCampoPersonalizadoDialog, setOpenCampoPersonalizadoDialog] =
    useState(false);
  const [permissoes, setPermissoes] = useState({});
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const tiposCampo = [
    {
      id: 1,
      type: "date",
      descricao: "Data",
    },
    {
      id: 2,
      type: "number",
      descricao: "Número",
    },
    {
      id: 3,
      type: "string",
      descricao: "Letras e Número",
    },
    {
      id: 4,
      type: "money",
      descricao: "Monetário",
    },
  ];
  const tiposPessoa = [
    {
      tipoId: 1,
      setLista: (dados) => setClienteList(dados),
    },
    {
      tipoId: 4,
      setLista: (dados) => setVendedorList(dados),
    },
  ];

  const buscarConfiguracao = async () => {
    const result = await configuracoesService.get();
    if (!result.isAxiosError) {
      const data = result.data.rows[0];
      setPermitirVendaEstoqueNegativo(data.vendaEstoqueNegativo);
      if (data.operacaoFiscalVendasPadraoId) {
        buscarOperacaoFiscal(data.operacaoFiscalVendasPadraoId);
        return true;
      }
    } else responseErros(result);
  };

  async function buscaDadosIniciais() {
    buscarPessoaVendedor(); //esta repetindo
    await Promise.all([buscaPedidos()]);
    buscaEstados();
    buscarMeiosPagamento();
    buscarCondicoesPagamento();
    buscarVendedores(); //esta repetindo
    buscarPermisoes();
    buscarOrigensMercadoria();
    const operacaoPadrao = await buscarConfiguracao();
    if (!operacaoPadrao) buscarOperacaoFiscal();
  }

  const buscarPermisoes = () => {
    const url = window.location.pathname.split("/");
    const modulo = url[2];
    const permisaoModulo = permisoesHelper.getPermisoesModulo(modulo);
    setPermissoes(permisaoModulo);
  };

  const buscarPessoaVendedor = async (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      vendedor: true,
    };
    const result = await colaboradorService.getAll(filtro);
    if (!result.isAxiosError) {
      const colaboradorVendedorUsuario = result.data.find(
        (colaborador) => colaborador.usuarioId === dadosUsuario.id
      );
      if (colaboradorVendedorUsuario) {
        setPedido({
          ...pedido,
          pessoaVendedorId: colaboradorVendedorUsuario.pessoa.id,
        });
      }
    } else {
      responseErros(result);
    }
  };

  function buscaDadosPedidoDto() {
    if (!pedidoDto) return;
    setPedido({
      ...pedidoDto,
      dataEmissao: formatDate.toSend(new Date()),
    });
    setListaPedidosProdutos(pedidoDto.itens);
    setPedidoDto({
      itens: [],
    });
  }

  useEffect(() => {
    buscaDadosIniciais().then(() => {
      setLoading(false);
    });
    //remover deve usar iqual em nf-saida
    buscaDadosPedidoDto();
  }, []);

  //remover fazer quandoa funcao for chamada
  useEffect(() => {
    setPedido((prevState) => ({
      ...prevState,
      totalPedido: totalPedido(pedido, listaPedidosProdutos),
      totalPagamentos: totalPagamentos(listaPedidosPagamentos),
    }));
    if (!pedido.pessoaClienteId) return;
    buscarDadosClientes(pedido.pessoaClienteId);
    buscaEnderecos(pedido.pessoaClienteId);
  }, [
    pedido?.pessoaClienteId,
    listaPedidosPagamentos,
    listaPedidosProdutos,
    pedido?.desconto,
    pedido?.acrescimo,
    pedido?.despesas,
  ]);

  //olhar a nessecidade mais deve ser removida
  useEffect(() => {
    if (!prevStateRef?.current || contadorModificacao <= (id ? 6 : 3)) {
      setContadorModificacao((prevCount) => prevCount + 1);
      prevStateRef.current = montarObjetoPedidos();
    }
  }, [pedido]);

  const buscaPedidos = async () => {
    if (!id) return;
    const response = await pedidosService.getById(id);
    if (!response.isAxiosError) {
      if (!response.data) {
        initialCollapses();
        voltar();
        return;
      }
      setInitialPedidoState(response.data);
      setPedido(response.data);
      setListaPedidosPagamentos(response.data?.pagamentos);
      buscarProdutosAvancado(response.data?.itens);
      setEnderecoAleterado(response.data.pedidoEnderecoCliente);
      buscarDadosClientes(pedido.pessoaClienteId);
      buscaEnderecos(pedido.pessoaClienteId);
      userLogs(response.data).then((logs) => {
        setUserLog(logs);
      });
    } else {
      responseErros(response);
      initialCollapses();
      voltar();
    }
  };

  const buscarPessoas = async (filtros, tiposPessoaId) => {
    const filtro = {
      ...filtros,
      tiposPessoaId,
      limite: 20,
      ativado: true,
    };
    const tipoPessoaTemp = tiposPessoa.find(
      ({ tipoId }) => tipoId === tiposPessoaId
    );
    pessoaService.getAll(filtro).then((res) => {
      setLoadingAutoCompleteCliente(false);
      if (!res.isAxiosError) {
        tipoPessoaTemp?.setLista(res.data.rows);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarVendedores = () => {
    const filtro = {
      nonPaginated: true,
      tiposPessoaId: 4,
      vendedor: true,
      ativado: true,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setVendedorList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaEstados = async () => {
    enderecoService.getEstados().then((res) => {
      if (!res.isAxiosError) {
        setEstados(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarOperacaoFiscal = (id) => {
    const filtros = {
      ativado: true,
      tipoOperacao: "SAIDA",
      restritiva: true,
      finalidade: ["NAO_SE_APLICA", "NFE_NORMAL"],
    };
    operacaoFiscalService.getAllFiltroAvancado(filtros).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data.rows);
        if (id && result.data.rows?.length) {
          const operacaoPadrao = result.data.rows.find(
            (operacao) => operacao.id === id
          );
          if (operacaoPadrao) {
            setPedido((prevState) => ({
              ...prevState,
              operacaoFiscalId: operacaoPadrao.id,
              operacaoFiscal: operacaoPadrao,
              serieDocumento: operacaoPadrao?.serieDocumento,
              modeloDocumento: operacaoPadrao?.modeloDocumento,
            }));
          }
        }
      } else {
        responseErros(result);
      }
    });
  };

  const buscarDadosClientes = async (clienteId) => {
    if (!clienteId) return;
    pessoaService.getById(clienteId).then((result) => {
      if (!result.isAxiosError) {
        setClienteSelecionado(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  //endereco deve vir junto do cliente
  const buscaEnderecos = async (clienteId) => {
    if (!clienteId) return;
    const enderecos = await pessoaService
      .getEnderecos(clienteId)
      .then((result) => {
        if (result.isAxiosError) {
          responseErros(result);
          return [];
        }
        return result.data;
      });
    const enderecoPadrao = encontrarEnderecoPadrao(enderecos);
    if (enderecoPadrao) {
      const enderecoCliente = await mapearEndereco(enderecoPadrao, estados);
      setEnderecoCliente(enderecoCliente);
    }
    const enderecosMapeados = await mapearEnderecos(enderecos);
    setEnderecoClienteDataGrid(enderecosMapeados);
  };

  const mapearEnderecos = (enderecos) => {
    return enderecos.map((endereco) => mapearEndereco(endereco, estados));
  };

  const buscarMeiosPagamento = (filtros) => {
    const filtro = {
      ...filtros,
      ativado: true,
      nonPaginated: true,
    };
    meioPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarCondicoesPagamento = () => {
    const filtro = {
      nonPaginated: true,
      ativado: true,
    };
    condicoesPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setCondicaoPagamentoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarProdutosAvancado = (itens) => {
    const produtosIds = itens.map(({ produtoId }) => produtoId);
    const filtro = {
      id: produtosIds,
      ativado: true,
      nonPaginated: true,
    };
    produtosService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setProdutoList(result.data);
        if (itens && itens.length) {
          preencherItens(itens, result.data);
        }
      } else {
        responseErros(result);
      }
    });
  };

  const buscarOrigensMercadoria = () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriasList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const preencherItens = (itensBusca, produtos) => {
    setListaPedidosProdutos(() => {
      const itens = itensBusca.map((item) => {
        const produtoBusca = produtos.find(
          (produto) => produto.id == item.produtoId
        );
        return {
          ...item,
          descricao: produtoBusca?.descricao,
          totalItem: calcularValorTotal(item),
        };
      });
      return itens;
    });
  };

  const buscarProdutosSimilares = async (produtoId) => {
    if (produtoId) {
      produtosService.getById(produtoId).then((result) => {
        if (!result.isAxiosError) {
          setProdutosSimilares(result.data.similares);
        } else {
          responseErros(result);
        }
      });
    }
  };

  const onInputChangeCamposPersonalizados = (event) => {
    setCampoPersonalizado({
      ...campoPersonalizado,
      [event.target.name]: event.target.value,
    });
  };

  const onSearchChangePessoas = (event, tiposPessoaId) => {
    if (
      event.key === "Enter" &&
      inputCliente.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      let filtros = {
        nomeRazaoSocial: eventValue,
        apelidoFantasia: eventValue,
      };
      if (tiposPessoaId === 4) {
        filtros = { ...filtros, vendedor: "true" };
      }
      setLoadingAutoCompleteCliente(true);
      buscarPessoas(filtros, tiposPessoaId);
    }
  };

  const handleChangeAutocomplete = (name, value) => {
    const pedidoTemp = {
      ...pedido,
      [name]: value ? value.id : null,
    };
    setPedido(pedidoTemp);
  };

  const handleChangeInput = (event) => {
    const pedidosTemp = {
      ...pedido,
      [event.target.name]: event.target.value || null,
    };
    setPedido(pedidosTemp);
  };

  const handleAdicionarCampoPersonalizado = () => {
    setCampoPersonalizado({});
    setOpenCampoPersonalizadoDialog(false);
  };

  //deve esta na funcao buscar dados inicial
  useEffect(() => {
    setItemValorComissaoVendedor(null);
    setItemPercentualComissaoVendedor(null);
    setItemTotalComissaoVendedor(null);
  }, []);

  const buscarProdutosCadastrados = () => {
    const filter = {
      nonPaginated: true,
      pedidoId: id,
    };
    pedidosItensService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        preencherItens(res.data, produtoList);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarTabelasPrecoCliente = async (tabelaPrecoId) => {
    if (!tabelaPrecoId) return;
    tabelaPrecoService.getById(tabelaPrecoId).then((result) => {
      if (!result.isAxiosError) {
        setTabelaPrecoCliente(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const handleCloseCadastroCliente = () => {
    buscarPessoas();
    setPedido((prevState) => ({
      ...prevState,
      pessoaClienteId: null,
    }));
    setIsCadastroPessoaDialogOpen(false);
  };

  const removerProdutoCadastrado = async (idProduto) => {
    setLoading(true);
    const res = await pedidosItensService.deletar(idProduto);
    if (!res.isAxiosError) {
      buscarProdutosCadastrados();
      notification.deletadoSucesso();
    } else {
      responseErros(res);
    }
    setLoading(false);
  };

  const salvarEndereco = async () => {
    const body = {
      ...endereco,
      cidadeId: cidadeId,
      pessoaId: pedido?.pessoaClienteId,
    };
    enderecoValidator
      .validate(body, { abortEarly: false })
      .then(async () => {
        setLoading(true);
        const res = await pessoaService.cadastrarEndereco(
          pedido?.pessoaClienteId,
          body
        );
        if (!res.isAxiosError) {
          notification.cadastroSucesso();
          setEnderecoClienteDataGrid([...enderecoClienteDataGrid, body]);
        } else {
          responseErros(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const verificaVendaPrazo = () => {
    const isBaixaAutomatica = verificaBaixaAutomatica(
      listaPedidosPagamentos,
      meioPagamentoList
    );
    if (!isBaixaAutomatica && !pedido.pessoaClienteId) {
      notification.alertaGenericos(
        "É necessário informar cliente para vendas a prazo"
      );
      setLoading(false);
      return true;
    }
    return false;
  };

  const validarPedidos = () => {
    if (verificaVendaPrazo()) return false;
    const pagamentosTotalPedidos = compararPagamentosTotalPedidos(
      pedido,
      listaPedidosProdutos
    );
    if (pagamentosTotalPedidos) {
      notification.alertaGenericos(
        "É necessário atualizar valor da(s) parcela(s)"
      );
      setLoading(false);
      setAbaValue(1);
      return false;
    }

    return true;
  };

  //deve ser montados na entidade
  const montarObjetoPedidos = () => {
    const pedidoEnderecoCliente = enderecoAleterado
      ? {
          ...enderecoAleterado,
          pessoaId: pedido?.pessoaClienteId,
        }
      : {
          ...enderecoCliente,
          pessoaId: pedido?.pessoaClienteId,
        };
    return new Pedido({
      ...pedido,
      itens: listaPedidosProdutos,
      pagamentos: listaPedidosPagamentos,
      vendedorObrigatorio: permissoes?.vendedorObrigatorio,
      pedidoEnderecoCliente,
    });
  };

  const handleSubmit = async (indiceSelecionado) => {
    setLoading(true);
    const pedidoDto = montarObjetoPedidos(); //remover
    pedidosValidator
      .validate(pedidoDto, { abortEarly: false })
      .then(async () => {
        const isValidar = validarPedidos();
        if (!isValidar) return setLoading(false);
        if (id) {
          const response = await pedidosService.atualizar(id, pedidoDto);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            history.goBack();
          } else {
            buscaPedidos();
            responseErros(response);
          }
        } else {
          const response = await pedidosService.cadastrar(pedidoDto);
          setLoading(false);
          if (!response.isAxiosError) {
            setPedido({ ...pedido, ...response.data });
            notification.cadastroSucesso();
            if (indiceSelecionado === 0) {
              const confirmacaoAlerta = await notification.confirmacaoGenericos(
                {
                  title: "Documento cadastrado com sucesso",
                  text: `#${response.data.numeroDocumento}`,
                  icon: "success",
                  confirmButtonText: "IMPRIMIR",
                  cancelButtonText: "FECHAR",
                  dangerMode: true,
                  showCancelButton: true,
                }
              );
              if (confirmacaoAlerta.isConfirmed) {
                document.getElementById("botaoMenuAction")?.click();
              } else {
                initialCollapses();
                voltar();
              }
            } else {
              handleCancelar();
            }
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.message.includes("pagamento")) {
          setAbaValue(1);
        }
        inputErros.set(err);
      });
    setPedidoDto({
      itens: [],
    });
  };

  const handleCancelar = () => {
    setPedido(initialPedidoState);
    setListaPedidosProdutos([]);
    setListaPedidosPagamentos([]);
    setListaPedidosPagamentos([]);
  };

  const onClickCancelarPedido = async () => {
    const retornoAlerta = await notification.confirmacao(
      "Cancelar!",
      "Tem certeza que deseja cancelar esse pedido?"
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const response = await pedidosService.cancelar(id);
      setLoading(false);
      if (!response.isAxiosError) {
        notification.alteracaoSucesso();
        history.push("/faturamento/pedidos");
      } else {
        responseErros(response);
      }
    }
  };

  const mediaQuery = useMediaQuery("(max-width:1024px)");

  return (
    <AddLayout
      id={id}
      title={pedido?.id ? "Editar pedidos" : "Cadastrar pedidos"}
      onClickSalvar={handleSubmit}
      loading={loading}
      codigo={
        id && pedido?.numeroDocumento
          ? `Nº Documento ${pedido?.numeroDocumento}`
          : ""
      }
      userLog={id ? userLog : null}
      ButtonMenu={
        <ButtonMenu
          id={id}
          data={{
            ...pedido,
            itens: listaPedidosProdutos,
            pagamentos: listaPedidosPagamentos,
            vendedor: value.autoComplete(vendedorList, pedido?.pessoaVendedorId)
              ?.nomeRazaoSocial,
            cliente: clienteSelecionado,
            enderecoCliente: enderecoAleterado || enderecoCliente,
            estadoCliente: enderecoCliente?.estado,
            totalItens: totalItensLista(listaPedidosProdutos),
            totalPedido: totalPedido(pedido, listaPedidosProdutos),
            meioPagamentoList,
          }}
          onClickCancelarPedido={onClickCancelarPedido}
        />
      }
      disableAtualizar={
        pedido.status !== "ABERTO" ||
        !permissoesHelper.temPermisao("pedidos-editar")
      }
      actionsTotalizadores={
        !mediaQuery && (
          <ResumoTotal
            abaValue={abaValue}
            pedido={pedido}
            setPedido={setPedido}
            listaPedidosProdutos={listaPedidosProdutos}
            totalItensLista={totalItensLista}
            totalPedido={totalPedido}
          />
        )
      }
      formState={{
        inicial: prevStateRef?.current,
        final: montarObjetoPedidos(),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4} md={1}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              id="serieDocumento"
              name="serieDocumento"
              label="Série"
              variant="filled"
              disabled
              value={pedido.serieDocumento ?? ""}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={8} md={3}>
          <DatePicker
            id="dataEmissao"
            name="dataEmissao"
            label="Data de Emissão"
            format="dd/MM/yyyy"
            variant="filled"
            disabled
            value={new Date()}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="operacaoFiscalId"
              name="operacaoFiscalId"
              disabled={id && pedido.status !== "ABERTO"}
              required
              options={operacaoFiscalList}
              noOptionsText="Sem opções"
              autoHighlight
              disableClearable
              getOptionLabel={(option) =>
                option.descricao ? `${option.descricao}` : ""
              }
              getOptionSelected={(option, value) =>
                option?.descricao === value?.descricao
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Operação Fiscal"
                  variant="outlined"
                  error={inputErros.get("operacaoFiscalId")}
                  required
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                setInputErro([]);
                handleChangeAutocomplete("operacaoFiscalId", newValue);
                setPedido((prevState) => ({
                  ...prevState,
                  operacaoFiscal: newValue,
                  serieDocumento: newValue?.serieDocumento,
                  modeloDocumento: newValue?.modeloDocumento,
                }));
              }}
              value={value.autoComplete(
                operacaoFiscalList,
                pedido?.operacaoFiscalId
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="pessoaClienteId"
              name="pessoaClienteId"
              disabled={id && pedido.status !== "ABERTO"}
              options={clienteList}
              autoHighlight
              getOptionLabel={(option) => {
                let descricaoProduto = `${option?.codigo} - ${option?.nomeRazaoSocial}`;
                return option ? descricaoProduto : "";
              }}
              loading={loadingAutoCompleteCliente}
              loadingText="Carregando"
              noOptionsText={
                document.getElementById("pessoaClienteId")?.value.length >=
                1 ? (
                  <Grid container spacing={1}>
                    <Grid item xs={7} className="mt-2" fullWidth>
                      Nenhum cliente encontrado
                    </Grid>
                    <Grid item xs={5} className="d-flex justify-content-end">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setIsCadastroPessoaDialogOpen(true)}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  "Digite e pressione Enter"
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cliente"
                  variant="outlined"
                  onKeyDown={(e) => onSearchChangePessoas(e, 1)}
                  inputRef={inputCliente}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <React.Fragment>
                          {loadingAutoCompleteCliente ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      </>
                    ),
                  }}
                />
              )}
              onChange={(e, newValue) => {
                setInputErro([]);
                setEnderecoClienteDataGrid([]);
                setEnderecoCliente(null);
                setEnderecoAleterado(null);
                handleChangeAutocomplete("pessoaClienteId", newValue);
                setClienteSelecionado(newValue);
                buscarDadosClientes(newValue?.id);
                buscarTabelasPrecoCliente(
                  newValue?.clientes[0]?.tabelaPrecoPadraoId
                );
                buscaEnderecos(newValue?.id);
              }}
              value={clienteSelecionado}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="pessoaVendedorId"
              name="pessoaVendedorId"
              disabled={id && pedido.status !== "ABERTO"}
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              options={vendedorList}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendedor"
                  variant="outlined"
                  required={permissoes?.vendedorObrigatorio}
                  error={inputErros.get("pessoaVendedorId")}
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                setInputErro([]);
                handleChangeAutocomplete("pessoaVendedorId", newValue);
              }}
              value={value.autoComplete(vendedorList, pedido?.pessoaVendedorId)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <hr className="mt-4 mb-2" />
      <Paper>
        <Tabs
          value={abaValue}
          onChange={(e, value) => {
            e.persist();
            setAbaValue(value);
          }}
          variant={mediaQuery ? "scrollable" : "fullWidth"}
          scrollButtons={mediaQuery ? true : false}
          allowScrollButtonsMobile={mediaQuery ? true : false}
          indicatorColor="primary"
          textColor="primary"
          className="mt-3"
        >
          <Tab label="ITENS DA NOTA" {...tabProps(0)} />
          <Tab
            disabled={
              totalPedido(pedido, listaPedidosProdutos) === 0 ? true : false
            }
            label="PAGAMENTOS"
            {...tabProps(1)}
          />
          <Tab label="DADOS DO CLIENTE" {...tabProps(2)} />
          <Tab label="INFORMAÇÕES ADICIONAIS" {...tabProps(3)} />
        </Tabs>
      </Paper>
      <CardContent>
        <TabPanel value={abaValue} index={0}>
          <PedidosItens
            id={id}
            pedido={pedido}
            setLoadingAutoCompleteProduto={setLoadingAutoCompleteProduto}
            loadingAutoCompleteProduto={loadingAutoCompleteProduto}
            produtoList={produtoList}
            setProdutoList={setProdutoList}
            calcularValorTotal={calcularValorTotal}
            listaPedidosProdutos={listaPedidosProdutos}
            setListaPedidosProdutos={setListaPedidosProdutos}
            setListaPedidosPagamentos={setListaPedidosPagamentos}
            tabelaPrecoCliente={tabelaPrecoCliente}
            buscarProdutosSimilares={buscarProdutosSimilares}
            produtosSimilares={produtosSimilares}
            removerProdutoCadastrado={removerProdutoCadastrado}
            permitirVendaEstoqueNegativo={permitirVendaEstoqueNegativo}
            origensMercadoriasList={origensMercadoriasList}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={1}>
          <PedidosPagamentos
            id={id}
            pedido={pedido}
            totalPedido={totalPedido}
            listaPedidosPagamentos={listaPedidosPagamentos}
            setListaPedidosPagamentos={setListaPedidosPagamentos}
            condicaoPagamentoList={condicaoPagamentoList}
            meioPagamentoList={meioPagamentoList}
            mediaQuery={mediaQuery}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={2}>
          {abaValue == 2 ? (
            <PedidosDadosClientes
              pedido={pedido}
              setPedido={setPedido}
              enderecoAleterado={enderecoAleterado}
              setEnderecoAleterado={setEnderecoAleterado}
              clienteList={clienteList}
              enderecoCliente={enderecoCliente}
              buscaEnderecos={buscaEnderecos}
              buscarDadosClientes={buscarDadosClientes}
              enderecoClienteDataGrid={enderecoClienteDataGrid}
              clienteSelecionado={clienteSelecionado}
              cidadeId={cidadeId}
              setCidadeId={setCidadeId}
              estados={estados}
              endereco={endereco}
              setEndereco={setEndereco}
              salvarEndereco={salvarEndereco}
              mediaQuery={mediaQuery}
              inputErros={inputErros}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={abaValue} index={3}>
          <PedidosCampos
            pedido={pedido}
            handleChangeInput={handleChangeInput}
          />
        </TabPanel>
        <FullscreenDialog
          open={isCadastroPessoaDialogOpen}
          handleClose={handleCloseCadastroCliente}
          content={
            <AddPessoas
              match={{ params: { id: null } }}
              isFullscreenDialog
              handleCloseFullscreenDialog={handleCloseCadastroCliente}
            />
          }
        />
      </CardContent>
      {mediaQuery && (
        <ResumoTotal
          abaValue={abaValue}
          pedido={pedido}
          setPedido={setPedido}
          listaPedidosProdutos={listaPedidosProdutos}
          totalItensLista={totalItensLista}
          totalPedido={totalPedido}
          mediaQuery={mediaQuery}
        />
      )}
      <ComissaoVendedorDialog
        openComissaoDialog={openComissaoDialog}
        setOpenComissaoDialog={setOpenComissaoDialog}
        itemValorComissaoVendedor={itemValorComissaoVendedor}
        setItemValorComissaoVendedor={setItemValorComissaoVendedor}
        itemPercentualComissaoVendedor={itemPercentualComissaoVendedor}
        setItemPercentualComissaoVendedor={setItemPercentualComissaoVendedor}
        itemTotalComissaoVendedor={itemTotalComissaoVendedor}
        setItemTotalComissaoVendedor={setItemTotalComissaoVendedor}
        listaPedidosProdutos={listaPedidosProdutos}
      />
      <CampoPersonalizadoDialog
        openCampoPersonalizadoDialog={openCampoPersonalizadoDialog}
        setOpenCampoPersonalizadoDialog={setOpenCampoPersonalizadoDialog}
        onInputChangeCamposPersonalizados={onInputChangeCamposPersonalizados}
        handleAdicionarCampoPersonalizado={handleAdicionarCampoPersonalizado}
        campoPersonalizado={campoPersonalizado}
        setCampoPersonalizado={setCampoPersonalizado}
        tiposCampo={tiposCampo}
      />
    </AddLayout>
  );
};

export default AddPedidosView;
