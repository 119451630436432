import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Button,
  Autocomplete,
  DataGrid,
} from "../../../../components";
import { ProdutosService, notification } from "../../../../services";
import { responseErros, formatPrice } from "../../../../utils";
import { value } from "../../../../helpers";

const FormKitComponent = ({
  kitProdutos,
  setKitProdutos,
  inserirPrecosKit,
}) => {
  let timeoutBuscaProduto = null;
  const produtoService = new ProdutosService();
  const [listaProdutos, setListaProdutos] = useState([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [quantidade, setQuantidade] = useState(null);

  const produtosColunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 400,
    },
    {
      field: "descricao",
      headerName: "Produtos",
      flex: 1100,
    },
    {
      field: "quantidade",
      headerName: "Qtd",
      flex: 500,
    },
    {
      field: "estoqueAtual",
      headerName: "Estoque",
      flex: 500,
    },
    {
      field: "precoCompra",
      headerName: "Preço Compra",
      flex: 500,
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "precoCusto",
      headerName: "Preço Custo",
      flex: 500,
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "precoVenda",
      headerName: "Preço Venda",
      flex: 500,
      valueGetter: (params) => formatPrice(params.value),
    },
  ];

  useEffect(() => {
    buscarProdutos();
  }, []);

  const buscarProdutos = (filtros) => {
    produtoService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setListaProdutos(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const handleChangeProdutoSelecionado = (e, value) => {
    if (e.target.name === "quantidade") {
      setQuantidade(parseFloat(e.target.value));
    } else setProdutoSelecionado(value);
    setListaProdutos([]);
  };

  const onInputChangeProdutos = (query) => {
    if (timeoutBuscaProduto) {
      clearTimeout(timeoutBuscaProduto);
    }
    timeoutBuscaProduto = setTimeout(() => {
      setListaProdutos([]);
      if (query.toString().length >= 1) {
        const filtros = {
          codigo: query,
          descricao: query,
        };
        buscarProdutos(filtros);
      } else {
        buscarProdutos();
      }
    }, 1000);
  };

  const handleAdicionarProduto = () => {
    if (!produtoSelecionado) {
      return notification.alertaGenericos(
        "Selecione um produto para adicionar"
      );
    }
    if (!quantidade) {
      return notification.alertaGenericos("Insira uma quantidade");
    }
    const produtoInserido = kitProdutos.find(
      (item) => item.id == produtoSelecionado.id
    );
    if (produtoInserido) {
      notification.alertaGenericos("Produto já inserido");
    } else {
      const produtoKitTemp = [
        ...kitProdutos,
        { ...produtoSelecionado, quantidade },
      ];
      setKitProdutos(produtoKitTemp);
      inserirPrecosKit(produtoKitTemp);
    }
    setQuantidade(null);
    setProdutoSelecionado(null);
  };

  const handleRemoverProduto = async (id) => {
    const data = kitProdutos.filter((item) => item.id !== id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${data.nome}?`
    );
    if (retornoAlerta.isConfirmed) {
      setKitProdutos(data);
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="produtoId"
              name="produtoId"
              noOptionsText={"Digite o nome do produto..."}
              options={listaProdutos}
              autoHighlight
              getOptionLabel={(option) => option?.descricao ?? ""}
              filterSelectedOptions
              onInputChange={(e, value) => onInputChangeProdutos(value)}
              onChange={handleChangeProdutoSelecionado}
              value={produtoSelecionado}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Buscar produto"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="quantidade"
            name="quantidade"
            label="Quantidade"
            variant="outlined"
            margin="normal"
            type="number"
            value={value.text(quantidade)}
            onChange={handleChangeProdutoSelecionado}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            className="mt-2"
            size="large"
            variant="contained"
            color="primary"
            onClick={handleAdicionarProduto}
          >
            <i
              className="ph-fill ph-plus-circle"
              style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
            ></i>
            Adicionar ao KIT
          </Button>
        </Grid>
      </Grid>
      <Grid className="mt-4" container spacing={4}>
        <Grid item xs={12}>
          <DataGrid
            rows={kitProdutos}
            columns={produtosColunas}
            rowCount={kitProdutos.length}
            deleteShow
            onClickDelete={handleRemoverProduto}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormKitComponent;
