import { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
  Autocomplete,
  Button,
} from "../../../../components";
import {
  GruposTributarioService,
  TiposItemService,
  CestService,
  OrigemMercadoriaService,
  NcmService,
  notification,
  ProdutosService,
  SessionService,
} from "../../../../services";
import { responseErros, timeoutBuscaAutocomplete } from "../../../../utils";
import "./text-area.scss";
import { value } from "../../../../helpers";

const FormFiscalComponent = ({
  produto,
  setProduto,
  inputErros,
  setLoading,
  ncmList,
  setNcmList,
  tabelaCest,
  setTabelaCest,
  loadingAutoComplete,
  setLoadingAutoComplete,
}) => {
  const inputRef = useRef();
  const inputCestRef = useRef();
  const [gruposTributacao, setGruposTributacao] = useState([]);
  const [origensMercadorias, setOrigensMercadorias] = useState([]);
  const [tiposItem, setTiposItem] = useState([]);
  const [autorizarConsultaConfisped, setAutorizarConsultaConfisped] =
    useState(false);
  const [confispedChanged, setConfispedChanged] = useState(false);
  const produtosService = new ProdutosService();
  const gruposTributarioService = new GruposTributarioService();
  const tiposItemService = new TiposItemService();
  const cestService = new CestService();
  const origemMercadoriaService = new OrigemMercadoriaService();
  const ncmService = new NcmService();
  const sessionService = new SessionService();

  useEffect(() => {
    buscarTiposItem();
    buscarGruposTributario();
    buscarOrigensMercadoria();
    const empresa = sessionService.getEmpresa();
    setAutorizarConsultaConfisped(empresa.autorizarConsultaConfisped);
  }, []);

  async function revisarProdutoFiscal(body) {
    if (!body?.ean) {
      return notification.alertaGenericos(
        "Código de barras é obrigatório para realizar 'Consulta Fiscal'"
      );
    }
    setLoading(true);
    const result = await produtosService.getConfispedApi(body);
    if (!result.isAxiosError) {
      if (result.data.ncm) {
        const grupoT = await buscarCsoGrupoTributario(result.data.cso);
        const ncm = await buscarNcms({ codigo: result.data.ncm });
        let produtoTemp = {
          ...produto,
          ncmId: ncm[0].id,
          ncmCodigo: ncm[0].codigo,
        };
        if (grupoT.id) {
          produtoTemp.grupoTributacaoId = grupoT.id;
        }
        setProduto(produtoTemp);
        notification.sucessoGenericos("Revisão realizada");
        setConfispedChanged(true);
      } else {
        notification.alertaGenericos(
          "Dados do produto não encontrados para consulta"
        );
      }
    } else {
      notification.alertaGenericos(
        "Dados do produto não encontrados para consulta"
      );
    }
    setLoading(false);
  }

  async function buscarCsoGrupoTributario(cso) {
    const res = await gruposTributarioService.getAll({
      csoSimplesNacional: cso,
    });
    if (!res.isAxiosError) {
      if (res.data) {
        return res.data.rows[0];
      }
    }
  }

  const buscarNcms = async (filtro) => {
    const filtros = {
      ...filtro,
      limite: 20,
    };
    const result = await ncmService.getAllAvancado(filtros);
    if (!result.isAxiosError) {
      setLoadingAutoComplete(false);
      setNcmList(result.data.rows);
      if (result.data.rows?.length) return result.data.rows;
    } else {
      setLoadingAutoComplete(false);
      responseErros(result);
    }
  };

  const buscarGruposTributario = () => {
    const filter = {
      ativado: true,
      nonPaginated: true,
    };
    gruposTributarioService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        setGruposTributacao(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarOrigensMercadoria = () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadorias(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarTiposItem = () => {
    tiposItemService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setTiposItem(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarCests = (filtros) => {
    const filtro = {
      ...filtros,
      limite: 20,
    };
    cestService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setLoadingAutoComplete(false);
        setTabelaCest(result.data.rows);
      } else {
        setLoadingAutoComplete(false);
        responseErros(result);
      }
    });
  };

  const onInputChangeCest = (event) => {
    if (
      event.key === "Enter" &&
      inputCestRef.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          codigo: eventValue,
        };
        setLoadingAutoComplete(true);
        timeoutBuscaAutocomplete(buscarCests(filtros));
      }
    }
  };

  const onInputChangeNcm = (event) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          codigo: eventValue,
        };
        setLoadingAutoComplete(true);
        timeoutBuscaAutocomplete(
          Promise.all([
            buscarNcms(filtros),
            buscarCests({ ncm: eventValue.substring(0, 4) }),
          ])
        );
      }
    }
  };

  const handleChangeAutocomplete = (name, value) => {
    const produtoTemp = {
      ...produto,
      [name]: value ? value.id : undefined,
    };
    if (name == "ncmId") {
      if (tabelaCest.length) {
        produtoTemp.cestId = tabelaCest[0]?.id;
      }
      produtoTemp.ncmCodigo = value?.codigo;
    }
    setProduto(produtoTemp);
  };

  const handleChangeInput = (event) => {
    setProduto({
      ...produto,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={autorizarConsultaConfisped ? 5 : 6}>
          <Autocomplete
            required
            id="origemMercadoriaId"
            name="origemMercadoriaId"
            options={origensMercadorias}
            noOptionsText="Sem opções"
            autoHighlight
            getOptionLabel={(option) =>
              option ? `${option?.codigo}-${option.descricao}` : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Origem da Mercadoria"
                variant="outlined"
                required
                error={inputErros.get("origemMercadoriaId")}
              />
            )}
            onChange={(_, value) =>
              handleChangeAutocomplete("origemMercadoriaId", value)
            }
            value={value.autoComplete(
              origensMercadorias,
              produto.origemMercadoriaId
            )}
          />
        </Grid>
        <Grid item xs={autorizarConsultaConfisped ? 5 : 6}>
          <Autocomplete
            id="tipoItemId"
            name="tipoItemId"
            options={tiposItem}
            noOptionsText="Sem opções"
            autoHighlight
            getOptionLabel={(option) => option.descricao ?? ""}
            renderInput={(params) => (
              <TextField {...params} label="Tipo do Item" variant="outlined" />
            )}
            onChange={(_, value) =>
              handleChangeAutocomplete("tipoItemId", value)
            }
            value={value.autoComplete(tiposItem, produto.tipoItemId)}
          />
        </Grid>
        {autorizarConsultaConfisped && (
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="small"
              sx={{
                height: "50px",
                minWidth: "15px",
                boxShadow: "none",
              }}
              onClick={() =>
                revisarProdutoFiscal({
                  ean: produto.codigoBarra,
                  descricao: produto.descricao,
                })
              }
            >
              Revisão Tributária
            </Button>
          </Grid>
        )}
        <Grid item xs={4}>
          <Autocomplete
            id="grupoTributacaoId"
            name="grupoTributacaoId"
            options={gruposTributacao}
            noOptionsText="Sem opções"
            autoHighlight
            disableClearable
            getOptionLabel={(option) => option.nome ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Grupo Tributário"
                variant="outlined"
                required
                color={confispedChanged && "success"}
                focused={confispedChanged}
                error={inputErros.get("grupoTributacaoId")}
              />
            )}
            onChange={(_, value) =>
              handleChangeAutocomplete("grupoTributacaoId", value)
            }
            value={value.autoComplete(
              gruposTributacao,
              produto.grupoTributacaoId
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="ncmId"
            name="ncmId"
            options={ncmList}
            autoHighlight
            loading={loadingAutoComplete}
            loadingText="Carregando"
            noOptionsText="Digite e pressione Enter"
            getOptionLabel={(option) => option?.codigo ?? ""}
            filterSelectedOptions
            onChange={(_, value) => handleChangeAutocomplete("ncmId", value)}
            value={value.autoComplete(ncmList, produto.ncmId)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="NCM"
                onKeyDown={(e) => onInputChangeNcm(e)}
                inputRef={inputRef}
                color={confispedChanged && "success"}
                focused={confispedChanged}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            id="cestId"
            name="cestId"
            options={tabelaCest}
            loadingText="Carregando"
            noOptionsText="Digite e pressione Enter"
            autoHighlight
            getOptionLabel={(option) => option?.codigo ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="CEST"
                onKeyDown={(e) => onInputChangeCest(e)}
                inputRef={inputCestRef}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            filterSelectedOptions
            filterOptions={(options, state) =>
              options.filter(
                (item) =>
                  item?.codigo?.includes(state.inputValue) ||
                  item?.ncmId?.includes(produto.ncmId)
              )
            }
            onChange={(_, value) => handleChangeAutocomplete("cestId", value)}
            value={value.autoComplete(tabelaCest, produto.cestId)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="cean"
            name="cean"
            label="Número Global do item"
            error={inputErros.get("cean")}
            type="number"
            variant={produto.tipo.id == "grade" ? "filled" : "outlined"}
            disabled={produto.tipo.id == "grade" ? true : false}
            value={
              produto.tipo.id != "grade" && produto.cean ? produto.cean : ""
            }
            onChange={handleChangeInput}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6}>
          <Typography className="text-start">
            Informação Adicional Nfe
          </Typography>
          <TextareaAutosize
            className="textRolagem"
            id="informacaoAdicionalNfe"
            name="informacaoAdicionalNfe"
            label="Informação Adicional Nfe"
            variant="outlined"
            maxLength={255}
            value={produto.informacaoAdicionalNfe ?? ""}
            onChange={handleChangeInput}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className="text-start">Observação</Typography>
          <TextareaAutosize
            className="textRolagem"
            id="informacaoComplementar"
            name="informacaoComplementar"
            label="Info. Complementar"
            variant="outlined"
            maxLength={255}
            value={produto.informacaoComplementar ?? ""}
            onChange={handleChangeInput}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormFiscalComponent;
