import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  CircularProgress,
  Autocomplete,
  DatePicker,
  Select,
  MenuItem,
} from "../../../../components";
import { OperacaoFiscalService, PessoaService } from "../../../../services";
import { responseErros, timeoutBuscaAutocomplete } from "../../../../utils";

const FormFiltroAvancado = ({
  valueInput,
  onChangeInput,
  valueDate,
  onChangeDate,
  valueAutocomplete,
  onChangeAutocomplete,
}) => {
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [clienteList, setClienteList] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const pessoaService = new PessoaService();
  const operacaoFiscalService = new OperacaoFiscalService();
  const tiposPessoa = [
    {
      tipoId: 1,
      setLista: (dados) => setClienteList(dados),
    },
    {
      tipoId: 4,
      setLista: (dados) => setVendedorList(dados),
    },
  ];
  const listaStatusNf = [
    {
      label: "Pendente",
      value: "PENDENTE",
      cor: "#EA9D2B",
    },
    {
      label: "Faturada",
      value: "FATURADA",
      cor: "#004780",
    },
    {
      label: "Enviada",
      value: "ENVIADA",
      cor: "#4FBB53",
    },
    {
      label: "Contingência",
      value: "CONTINGENCIA",
      cor: "#BFBFBF",
    },
    {
      label: "Cancelada",
      value: "CANCELADA",
      cor: "#DC3545",
    },
    {
      label: "Denegada",
      value: "Denegada",
      cor: "#70309F",
    },
  ];

  useEffect(() => {
    buscarOperacoesFiscais();
  }, []);

  const buscarOperacoesFiscais = () => {
    const filtro = {
      nonPaginated: true,
      ativado: true,
      modeloDocumento: ["55", "65"],
    };
    operacaoFiscalService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarPessoas = async (filtros, tiposPessoaId) => {
    const filtro = {
      ...filtros,
      tiposPessoaId,
      nonPaginated: true,
      ativado: true,
    };
    const tipoPessoaTemp = tiposPessoa.find(
      ({ tipoId }) => tipoId === tiposPessoaId
    );
    pessoaService.getAll(filtro).then((res) => {
      setLoadingAutoComplete(false);
      if (!res.isAxiosError) {
        tipoPessoaTemp.setLista(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const onInputChangePessoas = (event, value, tiposPessoaId) => {
    if (event?.type != "click" && event?.key != "Enter" && value?.length >= 2) {
      let filtros = {
        nomeRazaoSocial: value,
        apelidoFantasia: value,
      };
      if (tiposPessoaId === 4) {
        filtros = { ...filtros, vendedor: "true" };
      }
      setLoadingAutoComplete(true);
      timeoutBuscaAutocomplete(buscarPessoas(filtros, tiposPessoaId));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          id="numeroDocumento"
          name="numeroDocumento"
          label="Nº do Documento"
          variant="outlined"
          fullWidth
          onChange={(event) => onChangeInput(event)}
          value={valueInput("numeroDocumento")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="serieDocumento"
          name="serieDocumento"
          type="number"
          label="Série Documento"
          variant="outlined"
          onChange={(event) => onChangeInput(event)}
          value={valueInput("serieDocumento")}
          InputProps={{ inputProps: { min: 0 } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            id="clienteId"
            name="clienteId"
            options={clienteList}
            autoHighlight
            getOptionSelected={(option, value) =>
              option?.nomeRazaoSocial === value?.nomeRazaoSocial
            }
            getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
            loading={loadingAutoComplete}
            loadingText="Carregando"
            noOptionsText="Digite para buscar"
            onInputChange={(event, value) =>
              onInputChangePessoas(event, value, 1)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Cliente"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingAutoComplete ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            onChange={(e, value) => onChangeAutocomplete("clienteId", value)}
            value={valueAutocomplete(clienteList, "clienteId")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            id="vendedorId"
            name="vendedorId"
            options={vendedorList}
            autoHighlight
            getOptionSelected={(option, value) =>
              option?.nomeRazaoSocial === value?.nomeRazaoSocial
            }
            getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
            loading={loadingAutoComplete}
            loadingText="Carregando"
            noOptionsText="Digite para buscar"
            onInputChange={(event, value) =>
              onInputChangePessoas(event, value, 4)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vendedor"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingAutoComplete ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            onChange={(e, value) => onChangeAutocomplete("vendedorId", value)}
            value={valueAutocomplete(vendedorList, "vendedorId")}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Select
          id="status"
          name="status"
          value={valueInput("status")}
          label="Status"
          fullWidth
          onChange={onChangeInput}
        >
          {listaStatusNf.map((status, index) => (
            <MenuItem key={index} value={status.value}>
              <i
                className="ph-fill ph-circle"
                style={{ color: `${status.cor}` }}
              ></i>
              &nbsp;{status.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataEmissaoStart"
          name="dataEmissaoStart"
          label="Data Emissão De"
          format="dd/MM/yyyy"
          value={valueDate("dataEmissaoStart")}
          onChange={(date) => onChangeDate("dataEmissaoStart", date)}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="dataEmissaoEnd"
          name="dataEmissaoEnd"
          label="Data Emissão Até"
          format="dd/MM/yyyy"
          value={valueDate("dataEmissaoEnd")}
          onChange={(date) => onChangeDate("dataEmissaoEnd", date)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            required
            id="operacaoFiscalId"
            name="operacaoFiscalId"
            options={operacaoFiscalList}
            noOptionsText="Sem opções"
            autoHighlight
            getOptionLabel={(option) => (option ? option.descricao : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Operação Fiscal"
                variant="outlined"
                required
              />
            )}
            onChange={(e, value) =>
              onChangeAutocomplete("operacaoFiscalId", value)
            }
            value={valueAutocomplete(operacaoFiscalList, "operacaoFiscalId")}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default FormFiltroAvancado;
