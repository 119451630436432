export function validarLucro(produtoTemp, configuracoes) {
  if (
    !produtoTemp.precoCompra &&
    !produtoTemp.lucroDesejado &&
    !produtoTemp.precoCusto &&
    produtoTemp.precoVenda
  ) {
    produtoTemp.lucroDesejado = "0";
    produtoTemp.precoCompra = produtoTemp.precoVenda;
    produtoTemp.precoCusto = produtoTemp.precoVenda;
  }
  if (
    produtoTemp.precoCompra &&
    produtoTemp.precoCusto &&
    !produtoTemp.lucroDesejado
  ) {
    produtoTemp.lucroDesejado = "0";
    produtoTemp.precoVenda = produtoTemp.precoCusto;
  }
  if (produtoTemp.precoCompra && produtoTemp.precoCusto) {
    produtoTemp.precoVenda = calculoPrecoVenda(produtoTemp, configuracoes);
  }
  return produtoTemp;
}

export function validarPrecoVenda(produtoTemp, configuracoes, produto) {
  if (
    produtoTemp.precoCompra &&
    produtoTemp.precoCusto &&
    !produtoTemp.lucroDesejado &&
    !produtoTemp.precoVenda
  ) {
    produtoTemp.lucroDesejado = "0";
    produtoTemp.precoVenda = produtoTemp.precoCusto;
  }
  if (
    produtoTemp.precoCompra &&
    produtoTemp.precoCusto &&
    produtoTemp.precoVenda
  ) {
    produtoTemp.lucroDesejado = calculoLucro(
      produtoTemp,
      configuracoes,
      produto
    );
  }
  return produtoTemp;
}

export function calculoPrecoVenda(vendaLucroTemp, configuracoes) {
  if (vendaLucroTemp.precoCusto) {
    if (configuracoes?.margemCalculo === "INVERSA") {
      const lucro =
        parseFloat(vendaLucroTemp.lucroDesejado) >= 100
          ? (vendaLucroTemp.lucroDesejado = 99.99)
          : parseFloat(vendaLucroTemp.lucroDesejado);
      return parseFloat(vendaLucroTemp.precoCusto) / (1 - lucro / 100);
    } else {
      return (
        parseFloat(vendaLucroTemp.precoCusto) +
        parseFloat(vendaLucroTemp.precoCusto) *
          (parseFloat(vendaLucroTemp.lucroDesejado) / 100)
      );
    }
  }
}

export function calculoLucro(vendaLucroTemp, configuracoes, produto) {
  if (produto.precoCusto) {
    if (configuracoes?.margemCalculo === "INVERSA") {
      const calculoLucroInverso =
        (1 -
          parseFloat(vendaLucroTemp.precoCusto) /
            parseFloat(vendaLucroTemp.precoVenda)) *
        100;
      return calculoLucroInverso >= 0 ? calculoLucroInverso : 0;
    } else {
      const calculoLucroConvencional =
        ((parseFloat(vendaLucroTemp.precoVenda) -
          parseFloat(vendaLucroTemp.precoCusto)) /
          parseFloat(vendaLucroTemp.precoCusto)) *
        100;
      return calculoLucroConvencional >= 0 ? calculoLucroConvencional : "";
    }
  }
}
