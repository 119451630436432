import { useState, useEffect } from "react";
import {
  Autocomplete,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Checkbox,
  Button,
  FormControl,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import { TextFielDecimalNumbers, DataGrid } from "../../../components";
import {
  notification,
  MeioPagamentoService,
  CondicoesPagamentoService,
} from "../../../services";
import { InputErros, PermisoesHelper } from "../../../helpers";
import { responseErros, unformatPrice, userLogs, voltar } from "../../../utils";
import "./add-condicoes-pagamento.css";
import condicoesPagamentoValidator from "./middlewares/add-condicoes-pagamento.validator";
import { useImportContext } from "../../../contexts/import.context";
import { CondicaoPagamento } from "../entities";

const AtivadoComponente = ({ condicaoPagamento, setCondicaoPagamento }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={
            condicaoPagamento?.ativado ? condicaoPagamento.ativado : false
          }
          onChange={() =>
            setCondicaoPagamento((condicao) => {
              return {
                ...condicao,
                ativado: !condicaoPagamento?.ativado,
              };
            })
          }
          name="ativado"
          color="primary"
        />
      }
      label={condicaoPagamento?.ativado ? "Ativado" : "Desativado"}
    />
  );
};

const AddCondicaoPagamento = ({ match }) => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("condicoes-pagamento-visualizar");
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const [userLog, setUserLog] = useState({});
  const [loading, setLoading] = useState(false);
  const [initialCondicaoPagamentoState, setInitialCondicaoPagamentoState] =
    useState({
      ativado: true,
    });
  const [condicaoPagamento, setCondicaoPagamento] = useState({
    ativado: true,
    parcelas: 1,
  });
  const [meiosPagamentoList, setMeiosPagamentoList] = useState([]);
  const [meiosPagamento, setMeiosPagamento] = useState([]);
  const [
    filteredListaCondicaoPagamentoMeiosPagamento,
    setFilteredListaCondicaoPagamentoMeiosPagamento,
  ] = useState([]);
  const [
    listaCondicaoPagamentoMeiosPagamento,
    setListaCondicaoPagamentoMeiosPagamento,
  ] = useState([]);
  const [totalListaMeiosPagamento, setTotalListaMeiosPagamento] = useState(0);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const condicaoPagamentoService = new CondicoesPagamentoService();
  const meioPagamentoService = new MeioPagamentoService();
  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 500,
    },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 800,
    },
  ];

  async function buscarDadosIniciais() {
    await buscaCondicaoPagamento();
  }

  useEffect(() => {
    if (id) {
      buscarDadosIniciais();
    }
    buscaMeiosPagamento();
  }, [id]);

  const buscaCondicaoPagamento = async () => {
    setLoading(true);
    const { data } = await condicaoPagamentoService.getById(id);
    if (!data) {
      voltar();
      initialCollapses();
      return;
    }
    setInitialCondicaoPagamentoState(data);
    setCondicaoPagamento(data);
    setListaCondicaoPagamentoMeiosPagamento(data?.meiosPagamentos);
    userLogs(data).then((logs) => {
      setUserLog(logs);
    });
    setLoading(false);
  };

  const buscaMeiosPagamento = () => {
    const filtros = {
      nonPaginated: true,
      ativado: true,
    };
    meioPagamentoService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setMeiosPagamentoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const handleInputChange = (e) => {
    e.persist();
    setCondicaoPagamento((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleValoresChange = (e) => {
    e.persist();
    let targetValue = parseFloat(e.target.value);
    setCondicaoPagamento((prevState) => ({
      ...prevState,
      [e.target.name]: targetValue < 0 ? targetValue * -1 : targetValue,
    }));
  };

  const handleInputBlur = (e) => {
    e.persist();
    setCondicaoPagamento((prevState) => ({
      ...prevState,
      [e.target.name]: unformatPrice(e.target.value),
    }));
  };

  const handleAddMeioPagamento = async () => {
    const listFilter = listaCondicaoPagamentoMeiosPagamento.reduce(
      (acc, current) => {
        const i = acc.findIndex((item) => item?.id === current?.id);
        if (i !== -1) {
          acc.splice(i, 1);
          return acc.concat([current]);
        } else {
          return acc.concat([current]);
        }
      },
      []
    );
    setListaCondicaoPagamentoMeiosPagamento([...listFilter, ...meiosPagamento]);
    setMeiosPagamento([]);
  };

  const onClickDeleteMeioPagamento = async function (id) {
    const data = listaCondicaoPagamentoMeiosPagamento.filter(
      (item) => item?.id !== id
    );
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      "Tem certeza que deseja excluir meio de pagamento?"
    );
    if (retornoAlerta.isConfirmed) {
      setListaCondicaoPagamentoMeiosPagamento(data);
    }
  };

  const adicionarIdsMeiosPagamentosEmCondicaoPagamento = (
    listaCondicaoPagamentoMeiosPagamentoTemp
  ) => {
    const listFilter = listaCondicaoPagamentoMeiosPagamentoTemp.reduce(
      (acc, current) => {
        const i = acc.findIndex((item) => item?.id === current?.id);
        if (i !== -1) {
          acc.splice(i, 1);
          return acc.concat([current]);
        } else {
          return acc.concat([current]);
        }
      },
      []
    );
    setFilteredListaCondicaoPagamentoMeiosPagamento(listFilter);
    let produtoCount = 0;
    for (const item of listFilter) {
      if (item !== null) produtoCount++;
    }
    setTotalListaMeiosPagamento(produtoCount);
    setCondicaoPagamento((prevState) => ({
      ...prevState,
      meiosPagamentos: listFilter.map((x) => x.id),
    }));
  };

  useEffect(() => {
    adicionarIdsMeiosPagamentosEmCondicaoPagamento(
      listaCondicaoPagamentoMeiosPagamento
    );
  }, [listaCondicaoPagamentoMeiosPagamento]);

  const handleSubmit = async (indiceSelecionado) => {
    setLoading(true);
    condicoesPagamentoValidator
      .validate(condicaoPagamento, { abortEarly: false })
      .then(async () => {
        const dadosCondicao = new CondicaoPagamento(condicaoPagamento);
        if (id) {
          const response = await condicaoPagamentoService.atualizar(
            id,
            dadosCondicao
          );
          setLoading(false);
          if (!response.isAxiosError) {
            await buscaCondicaoPagamento();
            notification.alteracaoSucesso();
            voltar();
          } else {
            responseErros(response);
          }
        } else {
          const response = await condicaoPagamentoService.cadastrar(
            dadosCondicao
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            if (indiceSelecionado === 0) {
              voltar();
            } else {
              handleCancelar();
            }
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const handleCancelar = () => {
    setCondicaoPagamento(initialCondicaoPagamentoState);
    if (id) {
      adicionarIdsMeiosPagamentosEmCondicaoPagamento(
        initialCondicaoPagamentoState?.meiosPagamentos
      );
      setListaCondicaoPagamentoMeiosPagamento(
        initialCondicaoPagamentoState?.meiosPagamentos
      );
    } else {
      setListaCondicaoPagamentoMeiosPagamento([]);
    }
  };

  return (
    <AddLayout
      id={id}
      title={
        condicaoPagamento?.id
          ? "Editar condição de pagamento"
          : "Cadastrar condição de pagamento"
      }
      onClickSalvar={handleSubmit}
      loading={loading}
      userLog={id ? userLog : null}
      actions={
        <AtivadoComponente
          condicaoPagamento={condicaoPagamento}
          setCondicaoPagamento={setCondicaoPagamento}
        />
      }
      disableAtualizar={
        !permissoesHelper.temPermisao("condicoes-pagamento-editar")
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="descricao"
            name="descricao"
            label="Descrição"
            variant="outlined"
            margin="normal"
            value={
              condicaoPagamento?.descricao ? condicaoPagamento.descricao : ""
            }
            onChange={(e) => {
              setInputErro([]);
              handleInputChange(e);
            }}
            fullWidth
            required
            error={inputErros.get("descricao")}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            className="entrada"
            control={
              <Checkbox
                checked={
                  condicaoPagamento?.entrada ? condicaoPagamento.entrada : false
                }
                value={condicaoPagamento?.entrada}
                onChange={() => {
                  setCondicaoPagamento((prevState) => ({
                    ...prevState,
                    entrada: !condicaoPagamento?.entrada,
                  }));
                }}
                name="entrada"
                color="primary"
              />
            }
            label="Exige entrada"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextFielDecimalNumbers
            id="juros"
            name="juros"
            label="Juros"
            variant="outlined"
            margin="normal"
            value={condicaoPagamento?.juros ? condicaoPagamento.juros : ""}
            onBlur={handleInputBlur}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="periodo"
            name="periodo"
            label="Período"
            variant="outlined"
            margin="normal"
            inputProps={{ min: 0 }}
            value={condicaoPagamento?.periodo ? condicaoPagamento.periodo : ""}
            onChange={(e) => handleValoresChange(e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="carencia"
            name="carencia"
            label="Carência"
            variant="outlined"
            margin="normal"
            inputProps={{ min: 0 }}
            value={
              condicaoPagamento?.carencia ? condicaoPagamento.carencia : ""
            }
            onChange={(e) => handleValoresChange(e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="parcelas"
            name="parcelas"
            label="Parcelas"
            variant="outlined"
            margin="normal"
            type="number"
            required
            error={inputErros.get("parcelas")}
            inputProps={{ min: 1 }}
            value={condicaoPagamento?.parcelas ? condicaoPagamento.parcelas : 1}
            onChange={(e) => {
              setInputErro([]);
              handleValoresChange(e);
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <hr className="mt-5 mb-5" />
      <div className="mt-4 mb-2 fw-bold">MEIOS DE PAGAMENTO</div>
      <Grid container spacing={2} className="d-flex align-items-center mb-3">
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              multiple
              id="meiosPagamentos"
              name="meiosPagamentos"
              options={meiosPagamentoList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) =>
                option?.descricao ? option.descricao : ""
              }
              filterSelectedOptions
              onChange={(e, newValue) => {
                e.persist();
                setMeiosPagamento(newValue);
              }}
              value={meiosPagamento}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  required
                  label="Meios de pagamento"
                  error={inputErros.get("meiosPagamentos")}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            className="mt-2"
            variant="contained"
            color="primary"
            onClick={handleAddMeioPagamento}
          >
            <i
              className="ph-fill ph-plus-circle"
              style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
            ></i>
            Adicionar
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={filteredListaCondicaoPagamentoMeiosPagamento}
          columns={colunas}
          deleteShow={true}
          noFilter
          rowCount={totalListaMeiosPagamento}
          onClickDelete={onClickDeleteMeioPagamento}
        />
      </Grid>
    </AddLayout>
  );
};

export default AddCondicaoPagamento;
