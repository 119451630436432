import { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  Switch,
  Button,
  Autocomplete,
  Loading,
} from "../../../components";
import {
  notification,
  GrupoPessoasService,
  TabelaPrecoService,
  TiposPessoasService,
} from "../../../services";
import { InputErros, value, PermisoesHelper } from "../../../helpers";
import { responseErros } from "../../../utils";
import grupoPessoasValidator from "./middlewares/grupo-pessoas.validator";
import { GrupoPessoas } from "./entities";

const AddGrupoPessoas = ({
  id,
  handleCloseDialog,
  openDialog,
  buscaGruposPessoas,
}) => {
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("grupo-pessoas-visualizar");
  const [loading, setLoading] = useState(false);
  const [grupoPessoas, setGrupoPessoas] = useState({ ativado: true });
  const [tipoPessoa, setTipoPessoa] = useState(null);
  const [tabelaPrecoList, setTabelaPrecoList] = useState([]);
  const [tiposPessoaOptions, setTiposPessoaOptions] = useState([]);
  const grupoPessoasService = new GrupoPessoasService();
  const tiposPessoasService = new TiposPessoasService();
  const tabelaPrecoService = new TabelaPrecoService();
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  useEffect(() => {
    if (openDialog) {
      buscaTipoPessoa();
      buscaTabelasPreco();
      if (!id) return;
      buscaGrupoPessoas();
    }
  }, [openDialog]);

  const buscaGrupoPessoas = async () => {
    setLoading(true);
    const { data } = await grupoPessoasService.getById(id);
    setGrupoPessoas(data);
    setTipoPessoa(data?.tipoPessoa);
    setLoading(false);
  };

  const buscaTipoPessoa = async () => {
    const res = await tiposPessoasService.getAll();
    if (!res.isAxiosError) {
      setTiposPessoaOptions(res.data);
    }
  };

  const buscaTabelasPreco = async () => {
    const result = await tabelaPrecoService.getAll();
    if (!result.isAxiosError) {
      setTabelaPrecoList(result.data.rows);
    } else {
      responseErros(result);
    }
  };

  const handleInputChange = (e) => {
    e.persist();
    setGrupoPessoas((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const montarGrupoPessoas = () => {
    return {
      ...grupoPessoas,
      tipoPessoa: tipoPessoa ? tipoPessoa : null,
    };
  };

  const handleSubmit = async () => {
    const body = montarGrupoPessoas();
    const grupoPessoasDto = new GrupoPessoas(body);
    setLoading(true);
    grupoPessoasValidator
      .validate(grupoPessoasDto, { abortEarly: false })
      .then(async () => {
        if (id) {
          const response = await grupoPessoasService.atualizar(
            id,
            grupoPessoasDto
          );
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            handleCloseDialog();
            buscaGruposPessoas();
          } else responseErros(response);
        } else {
          const response = await grupoPessoasService.cadastrar(grupoPessoasDto);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            handleCloseDialog();
            buscaGruposPessoas();
          } else responseErros(response);
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const onChangeAutocomplete = (name, value) => {
    setGrupoPessoas((prevState) => ({
      ...prevState,
      [name]: value ? value.id : undefined,
    }));
  };

  return (
    <Container>
      <Card className="p-3">
        <CardHeader
          title={
            grupoPessoas?.id
              ? "Editar grupo de pessoas"
              : "Cadastrar grupo de pessoas"
          }
          action={
            <FormControlLabel
              control={
                <Switch
                  checked={grupoPessoas?.ativado ? grupoPessoas.ativado : false}
                  onChange={() =>
                    setGrupoPessoas((prevState) => ({
                      ...prevState,
                      ativado: !grupoPessoas?.ativado,
                    }))
                  }
                  name="ativado"
                  color="primary"
                />
              }
              label={grupoPessoas?.ativado ? "Ativado" : "Desativado"}
            />
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                id="nome"
                name="nome"
                label="Nome"
                variant="outlined"
                margin="normal"
                value={grupoPessoas?.nome ? grupoPessoas.nome : ""}
                onChange={(e) => {
                  setInputErro([]);
                  handleInputChange(e);
                }}
                fullWidth
                required
                error={inputErros.get("nome")}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <Autocomplete
                  required
                  id="tipoPessoa"
                  name="tipoPessoa"
                  options={tiposPessoaOptions}
                  noOptionsText="Sem opções"
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.descricao ? option.descricao : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de Pessoa"
                      variant="outlined"
                      error={inputErros.get("tipoPessoa")}
                      required
                      fullWidth
                    />
                  )}
                  onChange={(e, newValue) => {
                    e.persist();
                    setTipoPessoa(newValue?.id);
                    if (!newValue?.id) {
                      setGrupoPessoas((prevState) => ({
                        ...prevState,
                        tabelaPrecoId: null,
                      }));
                    }
                  }}
                  value={value.autoComplete(tiposPessoaOptions, tipoPessoa)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <Autocomplete
                  id="tabelaPrecoId"
                  name="tabelaPreco"
                  noOptionsText="Nenhuma tabela de preço encontrada"
                  options={tabelaPrecoList}
                  autoHighlight
                  getOptionLabel={(option) => (option.nome ? option.nome : "")}
                  disabled={tipoPessoa !== 1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tabela de preços"
                      variant={tipoPessoa !== 1 ? "filled" : "outlined"}
                      error={inputErros.get("tabelaPreco")}
                      fullWidth
                    />
                  )}
                  onChange={(e, newValue) => {
                    e.persist();
                    onChangeAutocomplete("tabelaPrecoId", newValue);
                  }}
                  value={value.autoComplete(
                    tabelaPrecoList,
                    grupoPessoas?.tabelaPrecoId
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            id="botaoSalvar"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!permissoesHelper.temPermisao("grupo-pessoas-cadastrar")}
          >
            <i
              className="ph-fill ph-check-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Salvar
          </Button>
          <Button
            color="inherit"
            variant="contained"
            onClick={handleCloseDialog}
          >
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Fechar
          </Button>
        </CardActions>
      </Card>
      <Loading loading={loading} />
    </Container>
  );
};

export default AddGrupoPessoas;
