export class Produto {
  ativado = null;
  imagem = null;
  codigoBarra = null;
  cean = null;
  grupoProdutoId = null;
  tipo = null;
  descricao = null;
  unidadeComercialCompraId = null;
  unidadeComercialVendaId = null;
  conversorEntrada = null;
  estoqueAtual = null;
  fracionavel = null;
  lerPesoPorBalanca = null;
  precoCompra = null;
  precoCusto = null;
  precoMedio = null;
  lucroDesejado = null;
  precoVenda = null;
  grupoTributacaoId = null;
  tipoItemId = null;
  ncmId = null;
  ncmCodigo = null;
  cestId = null;
  origemMercadoriaId = null;
  aliquotaMva = null;
  informacaoAdicionalNfe = null;
  informacaoComplementar = null;
  produtosSimilaresIds = null;
  fornecedores = null;
  produtosKitIds = null;
  camposPersonalizados = null;
  produtosGrade = null;
  gradeId = null;
  modeloGradeId = null;

  constructor(produto) {
    this.ativado = produto.ativado;
    this.imagem = produto.imagem;
    this.codigoBarra = produto.codigoBarra;
    this.cean = produto.cean;
    this.grupoProdutoId = produto.grupoProdutoId;
    this.tipo = produto.tipo;
    this.descricao = produto.descricao;
    this.unidadeComercialCompraId = produto.unidadeComercialCompraId;
    this.unidadeComercialVendaId = produto.unidadeComercialVendaId;
    this.conversorEntrada = produto.conversorEntrada;
    this.estoqueAtual = produto.estoqueAtual;
    this.fracionavel = produto.fracionavel;
    this.lerPesoPorBalanca = produto.lerPesoPorBalanca;
    this.precoCompra = isNaN(produto.precoCompra) ? null : produto.precoCompra;
    this.precoCusto = isNaN(produto.precoCusto) ? null : produto.precoCusto;
    this.precoMedio = isNaN(produto.precoMedio)
      ? null
      : produto.precoMedio || 0;
    this.precoVenda = isNaN(produto.precoVenda) ? null : produto.precoVenda;
    this.lucroDesejado = produto.lucroDesejado;
    this.grupoTributacaoId = produto.grupoTributacaoId;
    this.tipoItemId = produto.tipoItemId;
    this.ncmId = produto.ncmId;
    this.ncmCodigo = produto.ncmCodigo;
    this.cestId = produto.cestId;
    this.origemMercadoriaId = produto.origemMercadoriaId;
    this.aliquotaMva = produto.aliquotaMva;
    this.informacaoAdicionalNfe = produto.informacaoAdicionalNfe;
    this.informacaoComplementar = produto.informacaoComplementar;
    this.produtosSimilaresIds = produto.produtosSimilaresIds;
    this.fornecedores = produto.fornecedores?.length
      ? produto.fornecedores.map((fornecedor) => new Fornecedores(fornecedor))
      : [];
    this.produtosKitIds = produto.produtosKitIds;
    this.camposPersonalizados = produto.camposPersonalizados;
    this.gradeId = produto.gradeId;
    this.modeloGradeId = produto.modeloGradeId;
    this.produtosGrade = produto.produtosGrade?.length
      ? produto.produtosGrade.map(
          (produtoGrade) => new ProdutoGrade(produtoGrade)
        )
      : [];
  }
}

class Fornecedores {
  id = null;
  codigoProduto = null;
  quantidadeEmbalagem = null;

  constructor(fornecedor) {
    this.id = fornecedor.id;
    this.codigoProduto = fornecedor.codigoProduto;
    this.quantidadeEmbalagem = fornecedor.quantidadeEmbalagem;
  }
}

class ProdutoGrade {
  descricao = null;
  codigoBarra = null;
  tipo = null;
  eixoX = null;
  eixoY = null;

  constructor(produtoGrade) {
    this.descricao = produtoGrade.descricao;
    this.codigoBarra = produtoGrade.codigoBarra;
    this.tipo = produtoGrade.tipo;
    this.eixoX = produtoGrade.eixoX;
    this.eixoY = produtoGrade.eixoY;
  }
}
