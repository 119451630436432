import { Link as LinkMui } from "@mui/material";

const Link = ({
  className,
  children,
  color,
  href,
  to,
  component,
  ref,
  sx,
  typographyClasses,
  underline,
  variant,
  onClick,
}) => {
  return (
    <LinkMui
      className={className}
      color={color}
      component={component}
      href={href}
      to={to}
      onClick={onClick}
      ref={ref}
      sx={sx}
      typographyClasses={typographyClasses}
      underline={underline}
      variant={variant}
    >
      {children}
    </LinkMui>
  );
};

export default Link;
