import { Grid, TextFielDecimalNumbers } from "../../../../components";
import { unformatPrice } from "../../../../utils";
import "./text-area.scss";

const FormModeloTributacaoComponent = ({ produto, setProduto }) => {
  const handleChangeInput = (event) => {
    setProduto({
      ...produto,
      [event.target.name]: unformatPrice(event.target.value),
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextFielDecimalNumbers
            id="aliquotaMva"
            name="aliquotaMva"
            label="Alíquota MVA"
            margin="normal"
            onBlur={handleChangeInput}
            value={produto?.aliquotaMva ?? ""}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormModeloTributacaoComponent;
