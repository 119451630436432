import { useRef, useState } from "react";
import {
  Autocomplete,
  FormControl,
  Grid,
  TextFielDecimalNumbers,
  TextField,
} from "../../../../../../components";
import { formatPrice, timeoutBuscaAutocomplete } from "../../../../../../utils";
import { nfSaidaTotalTributos } from "../../../../entities";
import { InputErros } from "../../../../../../helpers";

const ImpostoProdutoIcmsTab = ({
  produto,
  produtoEditado,
  csoSimplesList,
  cfopList,
  onChangeAutocompleteCodigo,
  loadingAutoComplete,
  setLoadingAutoComplete,
  buscarCfops,
  impostoValueFormat,
  onChangeInput,
  nfSaida,
}) => {
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const inputRefCfop = useRef();

  const onInputChangeCfop = (event) => {
    if (
      event.key === "Enter" &&
      inputRefCfop.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          codigo: eventValue,
          descricao: eventValue,
        };
        setLoadingAutoComplete(true);
        timeoutBuscaAutocomplete(buscarCfops(filtros));
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} className="mt-1">
        <Grid item xs={2}>
          <TextField
            id="origem"
            name="origem"
            label="Origem"
            variant="outlined"
            margin="normal"
            disabled={true}
            value={produto?.tributosIcmsOrigem ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={5}>
          <Autocomplete
            id="tributosIcmsCsosn"
            name="tributosIcmsCsosn"
            options={csoSimplesList}
            autoHighlight
            getOptionLabel={(option) =>
              option ? `${option?.codigo} - ${option?.descricao}` : ""
            }
            getOptionSelected={(option, value) =>
              option?.descricao === value?.descricao
            }
            noOptionsText="Sem opções"
            disabled={!nfSaida?.documentoDevolucaoFornecedor}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Situação Tributária"
                variant="outlined"
                margin="normal"
                disabled={!nfSaida?.documentoDevolucaoFornecedor}
                fullWidth
              />
            )}
            onChange={(_, newValue) => {
              onChangeAutocompleteCodigo("tributosIcmsCsosn", newValue);
            }}
            value={impostoValueFormat(csoSimplesList, "tributosIcmsCsosn")}
          />
        </Grid>
        <Grid item xs={5}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="cfopId"
              name="cfopId"
              options={cfopList}
              autoHighlight
              getOptionLabel={(option) => {
                const optionDescricao = option?.descricao?.replace(".", "");
                return optionDescricao ? optionDescricao : "";
              }}
              filterSelectedOptions
              loading={loadingAutoComplete}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              disabled={!nfSaida?.documentoDevolucaoFornecedor}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar CFOP"
                  disabled={!nfSaida?.documentoDevolucaoFornecedor}
                  error={inputErros.get("cfopId")}
                  onKeyDown={onInputChangeCfop}
                  inputRef={inputRefCfop}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                onChangeAutocompleteCodigo("cfop", newValue);
              }}
              value={impostoValueFormat(cfopList, "cfop")}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField
            id="baseIcms"
            name="baseIcms"
            label="Base de ICMS"
            variant="outlined"
            margin="normal"
            disabled={true}
            value={formatPrice(
              nfSaidaTotalTributos.calculaBaseIcms(
                { ...produto, ...produtoEditado },
                nfSaida
              )
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFielDecimalNumbers
            id="tributosIcmsBaseCalculoPercentualReducao"
            name="tributosIcmsBaseCalculoPercentualReducao"
            label="% Redução ICMS"
            margin="normal"
            variant="outlined"
            fullWidth
            disabled={!nfSaida?.documentoDevolucaoFornecedor}
            value={
              produtoEditado?.tributosIcmsBaseCalculoPercentualReducao
                ? produtoEditado.tributosIcmsBaseCalculoPercentualReducao
                : produto?.tributosIcmsBaseCalculoPercentualReducao
            }
            onBlur={onChangeInput}
          />
        </Grid>
        <Grid item xs={2}>
          <TextFielDecimalNumbers
            id="tributosIcmsAliquota"
            name="tributosIcmsAliquota"
            label="Alíquota ICMS"
            margin="normal"
            variant="outlined"
            fullWidth
            disabled={!nfSaida?.documentoDevolucaoFornecedor}
            value={
              produtoEditado?.tributosIcmsAliquota
                ? produtoEditado.tributosIcmsAliquota
                : produto?.tributosIcmsAliquota
            }
            onBlur={onChangeInput}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="valorIcms"
            name="valorIcms"
            label="Valor de ICMS"
            variant="outlined"
            margin="normal"
            disabled={true}
            value={formatPrice(
              nfSaidaTotalTributos.calculaValorIcms(
                { ...produto, ...produtoEditado },
                nfSaida
              )
            )}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ImpostoProdutoIcmsTab;
