export function gerarPermissoes(modulos) {
  const permissoes = {};
  for (const modulo of modulos) {
    const elementos = modulo.filhos?.length > 0 ? modulo.filhos : [modulo];
    for (const elemento of elementos) {
      for (const permissao of elemento.permisaoModulo) {
        permissoes[permissao.nome] = {
          pctPermissoesModuloId: permissao.id,
          habilitado: true,
        };
      }
    }
  }
  return permissoes;
}
