import { Button, Grid, Link } from "../../components";
import { useImportContext } from "../../contexts/import.context";
import { voltar } from "../../utils";

const notFoundView = () => {
  const { initialCollapses } = useImportContext();
  const images = {
    notFound: require("../../assets/images/notfoundview.svg"),
  };

  return (
    <Grid>
      <Link href="/">
        <Button
          style={{
            background: "#dfe4f5",
            color: "#2152f3",
            position: "absolute",
            padding: "0.8rem 2rem",
            margin: 35,
            borderRadius: 19,
          }}
          onClick={() => {
            initialCollapses();
            voltar();
          }}
        >
          <i
            className="ph-fill ph-arrow-circle-left"
            style={{ fontSize: 20, color: "#2152f3", marginRight: 10 }}
          ></i>
          Voltar
        </Button>
      </Link>
      <Grid
        container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <img src={images.notFound.default} style={{ width: "100vh" }} />
      </Grid>
    </Grid>
  );
};

export default notFoundView;
