import { useState, useEffect, useRef } from "react";
import shortid from "shortid";
import {
  Autocomplete,
  Grid,
  FormControl,
  TextField,
  Button,
  IconButton,
  DataGrid,
  CurrencyInput,
  Tooltip,
} from "../../../../../components";
import {
  notification,
  ProdutosService,
  CfopService,
  UnidadeComercialService,
} from "../../../../../services";
import { InputErros, value } from "../../../../../helpers";
import {
  responseErros,
  formatPrice,
  unformatPrice,
  timeoutBuscaAutocomplete,
} from "../../../../../utils";
import {
  calculaSubtotalParaChave,
  calculaTotal,
  totalCompra,
} from "../../add-nf-entrada";
import { useImportContext } from "../../../../../contexts/import.context";
import ImpostoProdutoNfEntradaDialog from "../imposto-components/imposto-produto-dialog.component";

const NfEntradaItens = (props) => {
  const inputRef = useRef();
  const inputRefCfop = useRef();
  const { nfEntradaDto } = useImportContext();
  const produtoService = new ProdutosService();
  const cfopService = new CfopService();
  const unidadeComercialService = new UnidadeComercialService();
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [loadingAutoCompleteCfop, setLoadingAutoCompleteCfop] = useState(false);
  const [item, setItem] = useState(null);
  const [produto, setProduto] = useState(null);
  const [cfopItem, setCfopItem] = useState(null);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [cfopList, setCfopList] = useState([]);
  const [unidadeList, setUnidadeList] = useState([]);
  const [itensColumnView, setItensColumnView] = useState(false);
  const [isProdutoEdit, setIsProdutoEdit] = useState(false);
  const [openImpostoProdutoDialog, setImpostoProdutoDialog] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const produtosColunas = [
    {
      field: "ordem",
      headerName: "Ordem",
      flex: 100,
      sort: "asc",
    },
    {
      field: "codigo",
      headerName: "Código",
      flex: 200,
    },
    {
      field: "produto",
      headerName: "Produto",
      flex: 900,
    },
    {
      field: "quantidade",
      headerName: "Qtd.",
      flex: 200,
    },
    {
      field: "precoCompra",
      headerName: "Pr. Compra (R$)",
      flex: 330,
      valueGetter: (params) => formatPrice(parseFloat(params.value)),
    },
    {
      field: "subtotal",
      headerName: "Subtotal (R$)",
      flex: 330,
      valueGetter: (params) =>
        formatPrice(
          parseFloat(params.row?.valor ?? params.row?.precoCompra) *
            parseInt(params.row?.quantidade ?? 1)
        ),
    },
    {
      field: "total",
      headerName: "Total (R$)",
      flex: 330,
      valueGetter: (params) => formatPrice(calculaTotal(params.row)),
    },
    {
      field: "cfop",
      headerName: "CFOP",
      flex: 200,
    },
    {
      field: "acoes",
      sortable: false,
      disableClickEventBubbling: true,
      width: 100,
      renderHeader: () => {
        return (
          <i
            className="ph-fill ph-note-pencil"
            style={{ fontSize: 20, marginLeft: 25 }}
          ></i>
        );
      },
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                handleViewImposto(params.id);
              }}
            >
              <Tooltip title="Visualizar Imposto" arrow placement="right">
                <i className="ph-fill ph-eye" style={{ fontSize: 18 }}></i>
              </Tooltip>
            </IconButton>
            {!props.nfEntradaId ? (
              <IconButton onClick={() => handleEditarProduto(params.row)}>
                <i className="ph-fill ph-pencil" style={{ fontSize: 18 }}></i>
              </IconButton>
            ) : (
              ""
            )}
            {!props.nfEntradaId ? (
              <IconButton onClick={() => handleRemoverProduto(params.row)}>
                <i className="ph-fill ph-trash" style={{ fontSize: 18 }}></i>
              </IconButton>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    buscarUnidadesComerciais();
  }, []);

  const buscarProdutos = (filtros, isProdutoSimilar) => {
    const filtro = {
      ...filtros,
      orderBy: "codigo",
      order: "asc",
      ativado: true,
      limite: 20,
    };
    produtoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setLoadingAutoComplete(false);
        if (isProdutoSimilar) {
          setProduto(result.data.rows[0]);
          setItem({
            ...item,
            valor: result.data.rows[0].precoVenda,
          });
        }
        setListaProdutos(result.data.rows);
        if (result.data.rows?.length === 1) {
          handleChangeAutocompleteProduto(result.data.rows[0]);
        }
      } else {
        setLoadingAutoComplete(false);
        responseErros(result);
      }
    });
  };

  const buscarCfops = async (filtros) => {
    const filtro = {
      ...filtros,
      limite: 20,
    };
    const result = await cfopService.getAll(filtro);
    setLoadingAutoCompleteCfop(false);
    if (!result.isAxiosError) {
      setCfopList(result.data.rows);
      return result.data.rows;
    } else {
      responseErros(result);
    }
  };

  const handleInputChange = (e) => {
    e.persist();
    setItem((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputBlur = (e) => {
    e.persist();
    setItem((prevState) => ({
      ...prevState,
      [e.target.name]: unformatPrice(e.target.value),
    }));
  };

  const onInputChangeCfop = (event) => {
    if (
      event.key === "Enter" &&
      inputRefCfop.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      if (eventValue?.length >= 2) {
        const filtros = {
          codigo: eventValue,
          descricao: eventValue,
        };
        setLoadingAutoCompleteCfop(true);
        timeoutBuscaAutocomplete(buscarCfops(filtros));
      }
    }
  };

  const buscarUnidadesComerciais = async () => {
    const filtros = {
      nonPaginated: true,
    };
    unidadeComercialService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setUnidadeList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  function verificaProduto() {
    if (!nfEntradaDto.usoConsumo) {
      if (!produto) {
        return notification.alertaGenericos("Selecione um produto");
      }
      const produtoSelecionado = props.nfEntradaItens.find(
        (item) => item.produtoId == produto.id
      );
      if (produtoSelecionado) {
        return notification.alertaGenericos("Produto já inserido");
      }
    } else if (!item?.produto) {
      return notification.alertaGenericos("Insira o nome do produto");
    }
    if (item?.valor <= 0) {
      return notification.erroValidacao("Valor de compra do produto");
    }
  }

  function atualizaProdutos(itemForm) {
    if (!cfopItem) {
      inputErros.gerar("cfopId");
      return notification.alertaGenericos("CFOP é obrigatório");
    }
    const produtoNfId = !nfEntradaDto.usoConsumo ? produto.id : itemForm?.id;
    const produtoIndex = props.nfEntradaItens.findIndex(
      (obj) => obj.id === produtoNfId
    );
    const nfEntradaItens = itemForm?.itemEditado
      ? props.nfEntradaItens.map((obj, index) => {
          if (index == produtoIndex) {
            return {
              ...obj,
              ...itemForm,
              cfop: Number(cfopItem?.codigo),
              cfopId: cfopItem?.id,
              precoCompra: itemForm.valor ?? itemForm?.precoCompra,
              subtotal: itemForm.quantidade * itemForm.valor,
              total: calculaTotal(itemForm),
            };
          }
          return obj;
        })
      : [
          ...props.nfEntradaItens,
          {
            ...item,
            id: shortid.generate(),
            ordem: props.nfEntradaItens.length + 1,
            produtoId: !nfEntradaDto.usoConsumo ? produto.id : null,
            produto: !nfEntradaDto.usoConsumo
              ? produto.descricao
              : item.produto,
            quantidade: item?.quantidade ?? 1,
            precoCompra: item.valor ?? item?.precoCompra,
            subtotal: parseFloat(item.valor) * parseFloat(item?.quantidade),
            total: calculaTotal(item),
            cfop: Number(cfopItem?.codigo),
            cfopId: cfopItem?.id,
            codigo: item?.codigo,
          },
        ];
    props.setNfEntradaPagamentos([]);
    props.setNfEntradaItens(nfEntradaItens);
    props.setNfEntrada((prevState) => {
      const subtotalDesconto = calculaSubtotalParaChave(
        nfEntradaItens,
        "",
        "descontos"
      );
      const subtotalAcrescimo = calculaSubtotalParaChave(
        nfEntradaItens,
        "",
        "acrescimo"
      );
      const subtotalDespesas = calculaSubtotalParaChave(
        nfEntradaItens,
        "",
        "despesas"
      );
      const total = totalCompra(
        prevState,
        nfEntradaItens,
        props.nfEntradaTributos
      );
      props.setGerarPagamentos({
        valorTitulo: total,
      });
      return {
        ...prevState,
        subtotalDesconto,
        subtotalAcrescimo,
        subtotalDespesas,
        totalCompra: total,
      };
    });
    estadoInicialProduto();
  }

  function estadoInicialProduto() {
    setProduto(null);
    setItem(null);
    setCfopItem(null);
  }

  const handleAdicionarProduto = () => {
    verificaProduto();
    if (item) {
      atualizaProdutos(item);
      setIsProdutoEdit(false);
      setListaProdutos([]);
      setCfopList([]);
    }
  };

  const handleAddActionsOpen = () => {
    if (!item) {
      return notification.alertaGenericos("Selecione um produto");
    }
    setItensColumnView(!itensColumnView);
  };

  const handleEditarProduto = async (produtoSelecionado) => {
    const item = props.nfEntradaItens.find(
      (item) => item.id === produtoSelecionado.id
    );
    const cfopListEdit = await buscarCfops({ id: item.cfopId });
    if (!nfEntradaDto.usoConsumo) setProduto(item);
    setItem((prevState) => ({
      ...prevState,
      id: item.id,
      produto: item.produto,
      valor: item.valor ?? item.precoCompra,
      desconto: item.desconto,
      unidade: unidadeList.find(
        (unidadeId) => unidadeId.id == item?.unidadeComercialVendaId
      )?.codigo,
      acrescimo: item.acrescimo,
      despesas: item.despesas,
      quantidade: item.quantidade,
      itemEditado: true,
    }));
    setCfopItem(value.autoComplete(cfopListEdit, item?.cfopId));
    setIsProdutoEdit(true);
  };

  const handleRemoverProduto = async (produtoSelecionado) => {
    const data = props.nfEntradaItens.filter(
      (item) => item.id !== produtoSelecionado.id
    );
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${produtoSelecionado.descricao}?`
    );
    if (retornoAlerta.isConfirmed) {
      props.setNfEntradaItens(data);
    }
  };

  const handleChangeAutocompleteProduto = (produto) => {
    if (!produto) setListaProdutos([]);
    setProduto(produto);
    setItem((prevState) => ({
      ...prevState,
      ...produto,
      ncm: produto?.ncmCodigo,
      tributosIcmsOrigem: produto?.origemMercadoriaId,
      grupoTributacaoId: produto?.grupoTributacaoId,
      valor: produto?.precoCompra ?? 0,
      precoCompraCadastro: produto?.precoCompra ?? 0,
      precoCustoCadastro: produto?.precoCusto ?? 0,
      unidade: unidadeList.find(
        (item) => item.id == produto?.unidadeComercialVendaId
      )?.codigo,
      quantidade: 1,
    }));
    setCfopItem(props.cfop);
    document.getElementById("buttonAdicionar").focus();
  };

  const onInputChangeProdutos = (event) => {
    const eventValue = event.target?.value.includes(",")
      ? event.target?.value.split(",")[0]
      : event.target?.value;
    if (
      event.key === "Backspace" &&
      inputRef.current === document.activeElement &&
      eventValue === ""
    ) {
      setListaProdutos([]);
    }
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const filtros = {
        codigo: eventValue,
        descricao: eventValue,
      };
      if (
        typeof parseFloat(eventValue) === "number" &&
        eventValue?.length >= 8 &&
        eventValue?.length <= 13
      ) {
        filtros.codigoBarra = eventValue;
      }
      setLoadingAutoComplete(true);
      timeoutBuscaAutocomplete(buscarProdutos(filtros, false));
    }
  };

  const handleViewImposto = async (id) => {
    const item = props.nfEntradaItens.find((item) => item.id === id);
    setProduto(item);
    setImpostoProdutoDialog(true);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        className="mb-2"
        alignItems="center"
        columns={15}
      >
        <Grid item xs={4}>
          {nfEntradaDto.usoConsumo ? (
            <TextField
              id="produto"
              name="produto"
              variant={props.nfEntradaId ? "filled" : "outlined"}
              fullWidth
              margin="normal"
              label="Nome Produto"
              onChange={handleInputChange}
              value={value.text(item?.produto)}
            />
          ) : (
            <>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <Autocomplete
                  id="produtoId"
                  name="produtoId"
                  options={listaProdutos}
                  autoHighlight
                  disabled={props.nfEntradaId ? true : false}
                  loading={loadingAutoComplete}
                  loadingText="Carregando"
                  noOptionsText="Digite e pressione Enter"
                  getOptionLabel={(option) => {
                    let descricaoProduto = `${option?.codigo} - ${option?.descricao}`;
                    if (option.codigoBarra) {
                      descricaoProduto += ` - ${option.codigoBarra}`;
                    }
                    return option ? descricaoProduto : "";
                  }}
                  filterSelectedOptions
                  onChange={(_, newValue) =>
                    handleChangeAutocompleteProduto(newValue)
                  }
                  value={produto}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant={props.nfEntradaId ? "filled" : "outlined"}
                      label="Buscar produto"
                      onKeyDown={onInputChangeProdutos}
                      inputRef={inputRef}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </>
          )}
        </Grid>
        <Grid item xs={1}>
          <TextField
            disabled
            id="unidade"
            name="unidade"
            label="Unidade"
            variant="filled"
            margin="normal"
            value={item?.unidade ? item.unidade : ""}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={1.5}>
          <TextField
            id="quantidade"
            name="quantidade"
            label="Quantidade"
            variant="outlined"
            margin="normal"
            type="number"
            InputProps={
              item?.fracionavel
                ? { inputProps: { min: 0.01, step: 0.01 } }
                : { inputProps: { min: 1, step: 1 } }
            }
            value={value.text(item?.quantidade)}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            required
            id="valor"
            name="valor"
            label="Preço de Compra"
            value={item?.valor ? item.valor : ""}
            onBlur={handleInputBlur}
            error={props.inputErros.get("valor")}
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            disabled
            variant="filled"
            id="valorTotal"
            name="valorTotal"
            label="Subtotal"
            value={item?.valor * item?.quantidade}
            onBlur={handleInputBlur}
          />
        </Grid>
        <Grid item xs={3.5}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="cfopId"
              name="cfopId"
              options={cfopList}
              autoHighlight
              getOptionLabel={(option) => {
                const optionDescricao = option?.descricao?.replace(".", "");
                return optionDescricao ? optionDescricao : "";
              }}
              filterOptions={(options) =>
                options.filter((option) => option.codigo < 5000)
              }
              filterSelectedOptions
              loading={loadingAutoCompleteCfop}
              loadingText="Carregando"
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar CFOP"
                  required
                  error={inputErros.get("cfopId")}
                  onKeyDown={onInputChangeCfop}
                  inputRef={inputRefCfop}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                setCfopList([]);
                setCfopItem(newValue);
              }}
              value={cfopItem}
            />
          </FormControl>
        </Grid>
        <Grid item xs={0.5} sx={{ mt: "0.5em" }}>
          <IconButton
            variant="contained"
            size="small"
            onClick={handleAddActionsOpen}
          >
            <i
              className="ph-bold ph-caret-down"
              style={{ color: "#494c62" }}
            ></i>
          </IconButton>
        </Grid>
        <Grid item xs={0.5} sx={{ mt: "0.5em" }}>
          {isProdutoEdit ? (
            <Button
              id="buttonAdicionar"
              variant="contained"
              color="primary"
              size="small"
              sx={{
                height: "35px",
                borderRadius: "50%",
                minWidth: "15px",
                boxShadow: "none",
              }}
              onClick={handleAdicionarProduto}
            >
              <i
                className="ph-bold ph-arrows-clockwise"
                style={{ color: "#fff", fontSize: 15 }}
              ></i>
            </Button>
          ) : (
            <Button
              id="buttonAdicionar"
              variant="contained"
              color="success"
              size="small"
              sx={{
                height: "35px",
                borderRadius: "50%",
                minWidth: "15px",
                boxShadow: "none",
              }}
              onClick={handleAdicionarProduto}
            >
              <i
                className="ph-bold ph-plus"
                style={{ color: "#fff", fontSize: 15 }}
              ></i>
            </Button>
          )}
        </Grid>
      </Grid>
      {itensColumnView && (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <CurrencyInput
              id="desconto"
              name="desconto"
              label="Desconto"
              value={item?.desconto ? item.desconto : ""}
              onBlur={handleInputBlur}
            />
          </Grid>
          <Grid item xs={2}>
            <CurrencyInput
              id="acrescimo"
              name="acrescimo"
              label="Acréscimo"
              value={item?.acrescimo ? item.acrescimo : ""}
              onBlur={handleInputBlur}
            />
          </Grid>
          <Grid item xs={2}>
            <CurrencyInput
              id="despesas"
              name="despesas"
              label="Despesas"
              value={item?.despesas ? item.despesas : ""}
              onBlur={handleInputBlur}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mt: "32px" }}>
          <DataGrid
            rows={props.nfEntradaItens}
            columns={produtosColunas}
            rowCount={props.nfEntradaItens.length}
            onClickEdit={handleEditarProduto}
            columnVisibilityModel={{
              ordem: false,
              codigo: nfEntradaDto?.usoConsumo ? false : true,
            }}
            disableAcoes
          />
        </Grid>
      </Grid>
      <ImpostoProdutoNfEntradaDialog
        open={openImpostoProdutoDialog}
        setOpen={setImpostoProdutoDialog}
        produto={produto}
      />
    </>
  );
};

export default NfEntradaItens;
