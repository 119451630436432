import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  ImpressoraAdd,
  MenuButtonList,
} from "../../../../components";
import { Typography } from "../../../../components";
import { PermisoesHelper } from "../../../../helpers";
import {
  ModeloNfce,
  ModeloNfe,
  ModeloNfeEspelho,
  ModeloNfceContingencia,
} from "./modelos-impressao";
import MenuAddActions from "./menu-add-actions.component";
import { Calculo_DV11, GeraCodigoNumerico } from "../add-nf-saida";
import { format } from "date-fns";
import constantes from "../resources/constantes";

const ButtonMenu = ({
  id,
  nfSaida,
  dadosImpressao,
  onClickCancelarNfSaida,
  onClickEmitirNfSaida,
  onClickEmitirNfSaidaContigenciaEdicao,
  onClickValidarNfSaida,
  onClickCartaCorrecao,
  onClickConsultarStatus,
  onClickBaixarXml,
  operacaoFiscal,
  permissaoCancelar,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataImpressaoEspelho, setDataImpressaoEspelho] = useState(nfSaida);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-add-actions-menu";
  const permisoesHelper = new PermisoesHelper();
  const permisoesNfSaida = permisoesHelper.getPermisoesModulo("nf-saida");
  const permissaoEmitir =
    (operacaoFiscal.modeloDocumento === "65" && nfSaida.empresa?.emiteNfce) ||
    (operacaoFiscal.modeloDocumento === "55" && nfSaida.empresa?.emiteNfe);
  const opcoes = [
    {
      onClick: () => {
        onClickValidarNfSaida();
      },
      label: "Validar DF-e",
    },
    nfSaida.status === "PENDENTE" &&
      permissaoCancelar && {
        onClick: () => {
          onClickCancelarNfSaida();
        },
        label: "Cancelar DF-e",
        disabled: permisoesNfSaida?.cancelar ? false : true,
      },
  ];

  const handleAddActionsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function gerarChaveAcesso(nfSaida) {
    if (nfSaida) {
      const numeroDocumento = nfSaida.numeroDocumento
        ? nfSaida.numeroDocumento.toString().padStart(9, "0")
        : "";
      const uf = constantes.estados.find(
        (estado) => estado.sigla == nfSaida.empresa.uf
      ).cod;
      const initKey =
        uf +
        format(new Date(), "yyMM") +
        nfSaida.empresa?.cnpjCpf +
        "55" +
        nfSaida.serieDocumento +
        numeroDocumento +
        "1" +
        GeraCodigoNumerico(new Date());
      return initKey + Calculo_DV11(initKey);
    }
  }

  useEffect(() => {
    if (id && nfSaida.numeroDocumento && nfSaida.serieDocumento) {
      setDataImpressaoEspelho({
        ...nfSaida,
        chaveAcesso: gerarChaveAcesso(nfSaida),
      });
    }
  }, [nfSaida.numeroDocumento, nfSaida.serieDocumento]);

  function modeloImpressaoVisualizar() {
    if (!dadosImpressao) return;

    const modelos = {
      55: ModeloNfe,
      CONTINGENCIA: ModeloNfceContingencia,
      default: ModeloNfce,
    };

    const Modelo =
      modelos[dadosImpressao.modelo] ||
      modelos[nfSaida.status] ||
      modelos.default;

    return <Modelo data={dadosImpressao} />;
  }

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mb: "0.5em" }}
      >
        {id && (
          <>
            {nfSaida.status !== "PENDENTE" &&
              nfSaida.status !== "EM PROCESSAMENTO" &&
              nfSaida.status !== "FATURADA" && (
                <ImpressoraAdd
                  descricaoBotao="Visualizar DF-e"
                  LayoutImpressao={modeloImpressaoVisualizar()}
                />
              )}
            {id &&
            nfSaida?.modeloDocumento === "55" &&
            nfSaida.status === "PENDENTE" ? (
              <ImpressoraAdd
                descricaoBotao="Espelho DF-e"
                LayoutImpressao={
                  dataImpressaoEspelho && nfSaida.numeroDocumento ? (
                    <ModeloNfeEspelho data={dataImpressaoEspelho} />
                  ) : null
                }
              />
            ) : null}
            {nfSaida.status === "EM PROCESSAMENTO" && (
              <Button
                className="my-3 ms-2"
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => onClickConsultarStatus()}
              >
                Consultar DF-e
              </Button>
            )}
            {nfSaida.status === "PENDENTE" && (
              <MenuButtonList
                className="my-3 ms-2"
                botaoPrincipal={{
                  onClick: () => {
                    onClickEmitirNfSaida();
                  },
                  label: "Emitir DF-e",
                }}
                disabled={!permissaoEmitir}
                opcoes={opcoes}
              />
            )}
            {nfSaida.status === "CONTINGENCIA" && (
              <Button
                className="my-3 ms-2"
                variant="outlined"
                color="primary"
                size="small"
                disabled={!permissaoEmitir}
                onClick={() => onClickEmitirNfSaidaContigenciaEdicao()}
              >
                Emitir DF-e em Contingência
              </Button>
            )}
            <Button
              aria-label="add action caixa"
              aria-controls={menuId}
              aria-haspopup="true"
              aria-expanded={isMenuOpen ? "true" : "false"}
              onClick={handleAddActionsOpen}
              color="inherit"
              style={{
                borderRadius: 5,
                backgroundColor: "#E5E5E5",
                margin: "1em",
              }}
            >
              <span style={{ fontSize: 10 }}>mais ações</span>
            </Button>
          </>
        )}
        {/* botão menu para imprimir a danfe no create */}
        <div className="invisible" style={{ width: 5, height: 5 }}>
          <ImpressoraAdd
            descricaoBotao="Visualizar DF-e"
            LayoutImpressao={
              dadosImpressao && dadosImpressao?.modelo === "55" ? (
                <ModeloNfe data={dadosImpressao} />
              ) : (
                <ModeloNfce data={dadosImpressao} />
              )
            }
          />
        </div>
        {nfSaida.status === "CANCELADO" ? (
          <Typography style={{ color: "red" }}>CANCELADO</Typography>
        ) : null}
      </Grid>
      <MenuAddActions
        id={id}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        nfSaida={nfSaida}
        onClickCancelarNfSaida={onClickCancelarNfSaida}
        onClickCartaCorrecao={onClickCartaCorrecao}
        onClickBaixarXml={onClickBaixarXml}
        permissaoCancelar={permissaoCancelar}
      />
    </>
  );
};

export default ButtonMenu;
