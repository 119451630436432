export class PerfilUsuario {
  nome = null;
  permissoes = [];

  constructor(perfilUsuario, nome) {
    this.nome = nome;
    this.permissoes = this.mapearPermissoes(perfilUsuario);
  }

  mapearPermissoes(perfilUsuario) {
    const permissoes = [];
    for (const chave of Object.keys(perfilUsuario)) {
      if (chave !== "perfil") {
        permissoes.push(perfilUsuario[chave]);
      }
    }
    return permissoes;
  }
}
