import { useState, useEffect } from "react";
import { AddLayout } from "../../../layouts";
import { Grid, TextField, Autocomplete, Button } from "../../../components";
import { InputErros, PermisoesHelper } from "../../../helpers";
import { responseErros, voltar } from "../../../utils";
import {
  PerfilsUsuariosService,
  ModulosService,
  notification,
} from "../../../services";
import { perfilUsuarioValidator } from "./middlewares";
import { PerfilUsuario } from "./entities";
import { ModulosAcordionComponent } from "./components";
import { useImportContext } from "../../../contexts/import.context";
import { gerarPermissoes } from "./add-perfil-usuario";

const AddPerfilUsuario = ({ match }) => {
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [perfilUsuarios, setPerfilUsuarios] = useState([]);
  const [perfilPermisaoModulo, setPerfilPermisaoModulo] = useState({});
  const [modulos, setModulos] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const perfilsUsuariosService = new PerfilsUsuariosService();
  const modulosService = new ModulosService();
  const permissoesHelper = new PermisoesHelper();
  permissoesHelper.validarPermisao("perfis-usuarios-visualizar");
  async function buscarDadosIniciais() {
    if (id) {
      const perfilResult = await buscaPerfil(id);
      document.getElementById("nome").value = perfilResult.nome;
      montarPerfilPermissaoModulo(perfilResult.permissoesModulos);
    } else {
      buscarPerfilsCopia();
    }
    buscarModulos();
  }

  useEffect(() => {
    buscarDadosIniciais();
  }, []);

  const buscarPerfilsCopia = () => {
    const filtros = {
      padrao: "false",
    };
    perfilsUsuariosService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setPerfilUsuarios(result.data.rows);
      }
    });
  };

  function montarPerfilPermissaoModulo(permissoesModulos) {
    const perfilPermissoesModulos = {};
    for (const permissaoModulo of permissoesModulos) {
      perfilPermissoesModulos[permissaoModulo.permissaoModulo.nome] = {
        pctPermissoesModuloId: permissaoModulo.pctPermissoesModuloId,
        habilitado: permissaoModulo.habilitado,
      };
    }
    setPerfilPermisaoModulo(perfilPermissoesModulos);
  }

  const buscaPerfil = async (perfilUsuarioId) => {
    setLoading(true);
    const result = await perfilsUsuariosService.getById(perfilUsuarioId);
    if (!result.isAxiosError) {
      if (!result.data) {
        initialCollapses();
        voltar();
        return;
      }
      return result.data;
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarModulos = async () => {
    setLoading(true);
    const result = await modulosService.getAll();
    if (!result.isAxiosError) {
      setModulos(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickSalvarPerfil = async (indiceSelecionado) => {
    const perfilUsuarioDto = new PerfilUsuario(
      perfilPermisaoModulo,
      document.getElementById("nome").value
    );
    perfilUsuarioValidator
      .validate(perfilUsuarioDto, { abortEarly: false })
      .then(async () => {
        if (id) {
          atualizarPerfil(perfilUsuarioDto);
        } else {
          salvarPerfil(indiceSelecionado, perfilUsuarioDto);
        }
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const salvarPerfil = async (indiceSelecionado, perfilUsuarioDto) => {
    setLoading(true);
    const res = await perfilsUsuariosService.cadastrar(perfilUsuarioDto);
    setLoading(false);
    if (!res.isAxiosError) {
      notification.cadastroSucesso();
      if (indiceSelecionado === 0) {
        voltar();
      } else {
        buscarModulos();
      }
    } else {
      responseErros(res);
    }
  };

  const atualizarPerfil = async (perfilUsuarioDto) => {
    setLoading(true);
    const res = await perfilsUsuariosService.atualizar(id, perfilUsuarioDto);
    setLoading(false);
    if (!res.isAxiosError) {
      notification.alteracaoSucesso();
      voltar();
    } else {
      responseErros(res);
    }
  };

  const onChangePerfilUsuarioCopia = async (_, newValue) => {
    if (newValue) {
      setLoading(true);
      const perfilResult = await buscaPerfil(newValue.id);
      setLoading(false);
      montarPerfilPermissaoModulo(perfilResult.permissoesModulos);
    }
  };

  function onClickAdicionarTodasPermissoes() {
    setPerfilPermisaoModulo(gerarPermissoes(modulos));
    notification.sucessoGenericos("Permissões Adicionadas");
  }

  return (
    <AddLayout
      id={id}
      title={id ? "Editar Perfil Usuario" : "Cadastrar Perfil Usuario"}
      onClickSalvar={onClickSalvarPerfil}
      loading={loading}
      disableAtualizar={!permissoesHelper.temPermisao("perfis-usuarios-editar")}
      userLog={id ? {} : null}
      codigo={id ? `Código Nº ${id}` : null}
    >
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={3}>
          <TextField
            id="nome"
            name="nome"
            label="Nome"
            variant="outlined"
            fullWidth
            required
            error={inputErros.get("nome")}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="perfilUsuarioCopia"
            name="perfilUsuarioCopia"
            disabled={id ? true : false}
            getOptionLabel={(option) => option?.nome ?? ""}
            options={perfilUsuarios}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Perfil de Usuário Copia"
                variant="outlined"
              />
            )}
            onChange={onChangePerfilUsuarioCopia}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            type="button"
            onClick={onClickAdicionarTodasPermissoes}
            variant="contained"
            className="button-add"
            color="primary"
            sx={{ height: "52px" }}
          >
            ADICIONAR TODAS PERMISSÕES
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {modulos.map((modulo) => (
          <ModulosAcordionComponent
            key={modulo.id}
            modulo={modulo}
            setPerfilPermisaoModulo={setPerfilPermisaoModulo}
            perfilPermisaoModulo={perfilPermisaoModulo}
          />
        ))}
      </Grid>
    </AddLayout>
  );
};

export default AddPerfilUsuario;
