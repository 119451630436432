import { TablePrintDetalhadoComponent } from "../../../../components";
import { formatNomes, formatPrice } from "../../../../utils";
import HeaderRelatorioNfSaida from "./components/header-nf-saida-detalhado/header-nf-saida-detalhado.component";

const PrintNfSaidaDetalhado = ({
  filtrosAplicadosImpressao,
  relatoriosTotais,
  tituloRelatorio,
  print,
}) => {
  const columns = [
    {
      header: "Cod.",
      field: "codigo",
    },
    {
      header: "Descrição",
      field: "descricao",
      width: "57%",
      render: ({ descricao }) =>
        descricao && formatNomes(descricao.substring(0, 50)),
    },
    {
      header: "Quantidade",
      field: "quantidade",
      align: "center",
      width: "9%",
    },
    {
      header: "Valor",
      field: "valor",
      align: "start",
      width: "14%",
      render: ({ valor }) => formatPrice(valor, false),
    },
    {
      header: "SubTotal",
      field: "subtotal",
      align: "end",
      width: "14%",
      render: ({ subtotal }) => formatPrice(subtotal, false),
    },
  ];
  return (
    <TablePrintDetalhadoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais.nfSaida}
      totais={relatoriosTotais.totais}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      headerRelatorio={HeaderRelatorioNfSaida}
      styleTable={{ pl: 4, headColor: "#eff5ff" }}
    />
  );
};

export default PrintNfSaidaDetalhado;
